import React, { useState } from "react"
import {Marqy} from 'marqy'
import BlockContent from "@sanity/block-content-to-react"
import cx from 'classnames'

import { Serializer } from "src/utils/serializer"


import {
  SoundOn,
  SoundOff
} from 'src/components/svgs'

export interface VideoHeroProps {
  data: {
    text: any[]
  }
}


const BUBBLE_VARIANTS = {
  initial: {
    scale: 0,
    opacity: 0,
  },
  animate: {
    scale: 1,
    opacity: 1,
  },
}


const BUBBLES = [
  {
    key: 'dash',
    img: '/dash_c.png'
  },
  {
    key: 'spark',
    img: '/spark_c.png'
  },
  {
    key: 'zombie',
    img: '/zombie_c.png'
  },
  {
    key: 'flex',
    img: '/_c.png'
  },
]




export const VideoHero = ({ data }: VideoHeroProps) => {
  // const bubbles = BUBBLES.map((bubble, index) => {
  //   const dvdScreenSaver = useDvdScreenSaver({
  //     speed: 0.1,
  //   })
  
  //   return (
  //     <div
  //       key={bubble.key}
  //       className="bubble__container absolute w-full h-full top left z-20"
  //       ref={dvdScreenSaver.parentRef}>
  //       <div
  //         ref={dvdScreenSaver.childRef}
  //         style={{
  //           height: 90,
  //           width: 90
  //         }}>
  //         <motion.div
  //           {...bubble}
  //           {...BUBBLE_VARIANTS}
  //           transition={{
  //             duration: 200 / 1000,
  //             delay: 1
  //           }}
  //           className="bubble">
  //             <img src={bubble.img} className='c-r-s w-20 object-fit h-20' alt={`${bubble.key} cereal`} />
  //         </motion.div>
  //       </div>
  //     </div>
  //   )
  // })

  // useEffect(() => {
  //   setTimeout(() => {
  //     var resizeEvent = window.document.createEvent('UIEvents'); 
  //     resizeEvent.initUIEvent('resize', true, false, window, 0); 
  //     window.dispatchEvent(resizeEvent)
  //   }, 400)
  // })

  const [soundOn, toggleSoundOn] = useState(false)
  const videoRef = React.createRef()

  const toggleSound = () => {
    const video = videoRef.current
    if (video) {
      video.muted = soundOn
      toggleSoundOn(!soundOn)
    }
  }

  const { text, videoUrl, backgroundColor, marqueeText, posterImage } = data
  const marquee = marqueeText && (
    <Marqy className='mono pb-1 pt-1 bg-black text-gray text-14 uppercase' speed={0.2}>
      {marqueeText.map((marqtext, i) => (<span className='inline-block ml-2 mr-2' key={i}>&nbsp;{marqtext}&nbsp;</span>))}
    </Marqy>
  )
  return (
    <div className={cx('relative video__hero', backgroundColor)}>
      <div className=''>
        <img src='/dash_c.png' className='c-r-s w-24 h-24 absolute top-30 -ml-6 left-1/2 hidden z-10 800:block' alt='dash cereal' />
        <img src='/spark_c.png' className='c-r-s w-24 h-24 absolute top-1/4 -ml-6 right-20 hidden z-10 800:block' alt='spark cereal' />
        <img src='/zombie_c.png' alt='dash cereal' className='w-20 h-20 c-s absolute top-80 ml-4 hidden z-10 800:block' />
        <img src='/flex_c.png' className='c-r-s w-24 h-24 absolute top-3/4  left-3/4 hidden z-10 800:block' alt='flex cereal' />
      </div>
      <div className='grid grid-cols-1 800:grid-cols-2'>
        <div className='text-center flex items-center video__hero p-5 800:pl-12 800:pr-12 pt-20 800:pt-26 800:pb-12 1200:pt-18 1200:pb-11'>
          <div className='max-w-700 mx-auto pb-4'>
            {text && (<BlockContent blocks={text} serializers={Serializer} />)}
          </div>
        </div>
        <div className='800:hidden'>
          {marquee}
        </div>
        <div className='video relative border-b-2 800:border-b-0 800:border-l-2 800:min-h-[600px] 1000:min-h-[740px] border-black'>
          {videoUrl && (
            <video ref={videoRef} poster={posterImage.asset.url} src={videoUrl} playsInline muted loop autoPlay className='object-fit w-full h-full 800:absolute inset-0 top-0 left-0' />
          )}
          <div className='absolute bottom right m-2'>
            <div className=''>
              <button aria-label='turn on sound for video' className='hero__sound text-white rounded-full p-4 bg-black' onClick={() => toggleSound()}>
              {soundOn ? (
                <SoundOn className='h-8 w-8' />
              ) : (
                <SoundOff className='h-8 w-8' />
              )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden 800:block'>
        {marquee}
      </div>
    </div>
  )
}
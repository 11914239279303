import React from 'react'

// General Modules
import { StandardText, StandardTextProps } from 'src/components/global/standardText'
import { StandardHero, StandardHeroProps } from 'src/components/global/standardHero'
import { MainHero, MainHeroProps } from 'src/components/global/mainHero'
// import { CerealCTA, CerealCTAProps } from 'src/components/global/cerealCta'
// import { Shoppable, ShoppableProps } from 'src/components/global/shoppable'
// import { Instagram, InstagramProps } from 'src/components/global/instagram'
import { ProductGrid, ProductGridProps } from 'src/components/global/productGrid'
// import { NewFlavors, NewFlavorsProps } from 'src/components/global/newFlavors'
import { PressModule, PressModuleProps } from 'src/components/global/pressModule'
import { PressGrid, PressGridProps } from 'src/components/global/pressGrid'
import { FaqModule, FaqModuleProps } from 'src/components/global/faqModule'
import { DoubleCta, DoubleCtaProps } from 'src/components/global/doubleCta'
import { ImageSlideshow, ImageSlideshowProps } from 'src/components/global/imageSlideshow'
// import { TicketBreak, TicketBreakProps } from 'src/components/global/ticketBreak'
// import { MapModule, MapModuleProps } from 'src/components/global/mapModule'
import { VideoHero, VideoHeroProps } from 'src/components/global/videoHero'
import { ImageModule, ImageModuleProps } from 'src/components/global/imageModule'
import { Marquee, MarqueeProps } from 'src/components/global/marquee'
import { TwoColumn, TwoColumnProps } from 'src/components/global/twoColumn'
import { PressStory, PressStoryProps } from 'src/components/global/pressStory'
import { FanWorld, FanWorldProps } from 'src/components/global/fanWorld'
import { HeroSlideshow, HeroSlideshowProps } from 'src/components/global/heroSlideshow'
import { BundleBuilder, BundleBuilderProps } from 'src/components/global/bundleBuilder'
import { AboutExperience, AboutExperienceProps } from 'src/components/global/aboutExperience'
import { ToyStoreBlock, ToyStoreBlockProps } from 'src/components/global/toyStoreBlock'
import { TextSwitcher, TextSwitcherProps } from 'src/components/global/textSwitcher'
import { Partners, PartnersProps } from 'src/components/global/partners'
import { Spacer, SpacerProps } from 'src/components/global/spacer'
import { SplitModule, SplitModuleProps } from 'src/components/global/splitModule'
import { ImageText, ImageTextProps } from 'src/components/global/imageText'
// import { NewsletterModule, NewsletterModuleProps } from 'src/components/global/newsletter'

// Product Specific Modules for now
import { Warning, WarningProps } from 'src/components/product/warning'
import { GalleryGrid, GalleryGridProps } from 'src/components/product/galleryGrid'
// import { HowItWorks, HowItWorksProps } from 'src/components/product/howItWorks'
import { FloatingHead, FloatingHeadProps } from 'src/components/product/floatingHead'
import { VarietyPack, VarietyPackProps } from 'src/components/product/varietyPack'
import { ProductCTA, ProductCTAProps } from 'src/components/product/productCta'
import { ISpy, ISpyProps } from 'src/components/product/iSpy'
import { TextWindow, TextWindowProps } from 'src/components/product/textWindow'
import { ReviewsBlock } from 'src/components/product/reviewsBlock'
import { ProductPress, ProductPressProps } from 'src/components/product/productPress'
import { ColumnSlideshow, ColumnSlideshowProps } from 'src/components/product/columnSlideshow'
import { CoreFeatures, CoreFeaturesProps } from 'src/components/product/coreFeatures'
import { ProductInstagram, ProductInstagramProps } from 'src/components/product/productInstagram'
import { IncludedFlavors, IncludedFlavorsProps } from 'src/components/product/includedFlavors'
import { Video, VideoProps } from 'src/components/product/video'
import { PopupZone, PopupZoneProps } from 'src/components/product/popupZone'
import { IngredientsBlock, IngredientsBlockProps } from 'src/components/product/ingredientsBlock'
import { Reviews, ReviewsProps } from 'src/components/product/reviews'
import { Chatroulette, ChatrouletteProps } from 'src/components/product/chatroulette'

export const Modules = ({ reactModule, type, cereal, product }: {
  type: string
  reactModule: any
  cereal?: {}
  product?: {}
}) => { 
  switch(type) {
    case 'standardText':
      return <StandardText data={reactModule as StandardTextProps["data"]} />
    // case 'howItWorks':
    //   return <HowItWorks main={cereal as HowItWorksProps["main"]} data={reactModule as HowItWorksProps["data"]} />
    case 'popupZone':
      return <PopupZone  main={cereal as PopupZoneProps["main"]} shopify={product as PopupZoneProps["shopify"]} data={reactModule as PopupZoneProps["data"]} />
    case 'reviews':
      return <Reviews  main={cereal as ReviewsProps["main"]} data={reactModule as ReviewsProps["data"]} />
    case 'floatingHead':
      return <FloatingHead data={reactModule as FloatingHeadProps["data"]} />
    
    case 'productInstagram':
      return <ProductInstagram main={cereal as PopupZoneProps["main"]}  data={reactModule as ProductInstagramProps["data"]} />
    case 'chatroulette':
      return <Chatroulette main={cereal as PopupZoneProps["main"]}  data={reactModule as ChatrouletteProps["data"]} />
    case 'imageModule':
      return <ImageModule data={reactModule as ImageModuleProps["data"]} />
    case 'productPress':
      return <ProductPress data={reactModule as ProductPressProps["data"]} />
    case 'textWindow':
      return <TextWindow data={reactModule as TextWindowProps["data"]} />
    case 'textSwitcher':
      return <TextSwitcher data={reactModule as TextSwitcherProps["data"]} />
    case 'twoColumn':
      return <TwoColumn data={reactModule as TwoColumnProps["data"]} />
    case 'heroSlideshow':
      return <HeroSlideshow data={reactModule as HeroSlideshowProps["data"]} />
    case 'pressStory':
      return <PressStory data={reactModule as PressStoryProps["data"]} />
    case 'marquee':
      return <Marquee data={reactModule as MarqueeProps["data"]} />
    case 'imageSlideshow':
      return <ImageSlideshow data={reactModule as ImageSlideshowProps["data"]} />
    case 'bundleBuilder':
      return <BundleBuilder data={reactModule as BundleBuilderProps["data"]} />
    case 'columnSlideshow':
      return <ColumnSlideshow data={reactModule as ColumnSlideshowProps["data"]} />
    case 'iSpy':
      return <ISpy data={reactModule as ISpyProps["data"]} />
    case 'varietyPack':
      return <VarietyPack data={reactModule as VarietyPackProps["data"]} />
    case 'toyStoreBlock':
      return <ToyStoreBlock data={reactModule as ToyStoreBlockProps["data"]} />
    case 'fanWorld':
      return <FanWorld data={reactModule as FanWorldProps["data"]} />
    case 'mainHero':
      return <MainHero data={reactModule as MainHeroProps["data"]} />
    case 'coreFeatures':
      return <CoreFeatures main={cereal as PopupZoneProps["main"]}  data={reactModule as CoreFeaturesProps["data"]} />
    case 'doubleCta':
      return <DoubleCta data={reactModule as DoubleCtaProps["data"]} />
    case 'videoHero':
      return <VideoHero data={reactModule as VideoHeroProps["data"]} />
    case 'includedFlavors':
      return <IncludedFlavors data={reactModule as IncludedFlavorsProps["data"]} />
    case 'video':
      return <Video data={reactModule as VideoProps["data"]} />
    case 'partners':
      return <Partners data={reactModule as PartnersProps["data"]} />
    // case 'ticketBreak':
    //   return <TicketBreak data={reactModule as TicketBreakProps["data"]} />
    case 'aboutExperience':
      return <AboutExperience data={reactModule as AboutExperienceProps["data"]} />
    case 'productGrid':
      return <ProductGrid data={reactModule as ProductGridProps["data"]} />
    case 'imageText':
      return <ImageText data={reactModule as ImageTextProps["data"]} />
    case 'splitModule':
      return <SplitModule data={reactModule as SplitModuleProps["data"]} />
    // case 'newsletter':
    //   return <NewsletterModule data={reactModule as NewsletterModuleProps["data"]} />
    case 'ingredientsBlock':
      return <IngredientsBlock data={reactModule as IngredientsBlockProps["data"]} />
    case 'faqModule':
      return <FaqModule data={reactModule as FaqModuleProps["data"]} />
    // case 'mapModule':
    //   return <MapModule data={reactModule as MapModuleProps["data"]} />
    case 'spacer':
      return <Spacer data={reactModule as SpacerProps["data"]} />
    // case 'newFlavors':
    //   return <NewFlavors data={reactModule as NewFlavorsProps["data"]} />
    case 'galleryGrid':
      return <GalleryGrid data={reactModule as GalleryGridProps["data"]} />
    case 'standardHero':
      return <StandardHero data={reactModule as StandardHeroProps["data"]} />
    case 'productCTA':
      return <ProductCTA data={reactModule as ProductCTAProps["data"]} />
    // case 'shoppable':
    //   return <Shoppable data={reactModule as ShoppableProps["data"]} />
    case 'pressGrid':
      return <PressGrid data={reactModule as PressGridProps["data"]} />
    case 'reviewsBlock':
      return <ReviewsBlock shopify={product}  data={reactModule} />
    case 'pressModule':
      return <PressModule data={reactModule as PressModuleProps["data"]} />
    // case 'instagram':
    //   return <Instagram data={reactModule as InstagramProps["data"]} />
    // case 'cerealCta':
    //   return <CerealCTA data={reactModule as CerealCTAProps["data"]} />
    case 'warningModule':
      return <Warning data={reactModule as WarningProps["data"]} />
    default:
      return (<div className='h1 '>{type}</div>)
  }
}
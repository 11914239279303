import React, { useState, useRef } from "react";
import cx from "classnames";
import { useIntersection } from "use-intersection";

import Draggable from "react-draggable";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import BlockContent from "@sanity/block-content-to-react";

import { Marqy } from "marqy";
import { Image } from "src/components/image";
import { Serializer } from "src/utils/serializer";
import { DayInTheLife } from "src/components/global/dayInLife";

export interface AboutExperienceProps {
  data: {
    videoBlock: string;
    titleText: string;
    about: any[];
    characters: any[];
  };
}

const Box = ({
  title,
  classNames,
  smaller = false,
  children,
  wrapperClass,
  cereal
}) => {
  const layoutRef = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const isIntersecting = useIntersection(layoutRef, {
    once: true,
    threshold: 0.3
  });

  return (
    <div
      className={cx(" duration-200 scale-50 transform", {
        "scale-100": isIntersecting,
        "scale-50": !isIntersecting
      })}
      ref={layoutRef}
    >
      <Draggable>
        <div
          className={cx(
            "relative z-10 box-shadow-sm shadow-popup border-2 border-solid border-black",
            wrapperClass,
            {}
          )}
        >
          <div
            className={cx(
              "p-1 border-b-2 border-solid text-black js-drag cursor-move flex justify-between items-center",
              classNames
            )}
          >
            <span className="mono text-14 uppercase ml-2">{title}</span>
            {/* <div className={cx('ml-4 h-6 flex justify-between flex-wrap items-center mr-1', {
                ' w-8/12 600:w-9/12 800:w-10/12': !smaller,
                'w-6/12 800:w-9/12': smaller
              })}>
                <span className='border border-solid border-black w-full block' />
                <span className='border border-solid border-black w-full block' />
                <span className='border border-solid border-black w-full block' />
              </div> */}
            <div className="text-right">
              {cereal && (
                <img
                  src={cereal}
                  alt={`${title} cereal bit`}
                  className="h-8 select-none pointer-events-none inline-block w-8 p-1"
                />
              )}
            </div>
          </div>
          {children}
        </div>
      </Draggable>
    </div>
  );
};

export const AboutExperience = ({ data }: AboutExperienceProps) => {
  const [active, setSlide] = useState(0);
  const {
    titleText,
    videoBlock,
    about,
    founderStory,
    founderImage,
    collabs,
    givingBack,
    givingBackImage,
    sustainabilityStory,
    sustainabilityImage,
    marqueeText,
    characters
  } = data;

  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    slideChanged(s) {
      setSlide(s.details().relativeSlide);
    }
  });

  return (
    <div className="bg-computer border-b-2 border-solid border-black relative">
      <div className="absolute -left-30 -top-52 hidden 800:block">
        <img src="/dash_c.png" alt="dash cereal" className="w-1/2 c-s mt-10" />
      </div>
      <div className="p-5 max-w-1400  mx-auto pt-24 pb-8 800:pl-8 800:pr-8 800:pt-40 1200:pl-12 1200:pr-12">
        <h1 className="text-48 leading-48 800:text-100 1200:text-150 offlimits text-white uppercase">
          {titleText}
        </h1>
      </div>
      <div className="grid max-w-1400 mx-auto grid-cols-1 p-5 800:p-8 1200:p-12 800:grid-cols-2 800:gap-20">
        <div>
          <div className="relative">
            <video
              className="rounded-3xl overflow-hidden 800:w-11/12"
              src={videoBlock}
              autoPlay
              loop
              muted
              playsInline
            />
            <div className="absolute -left-40 -bottom-50 hidden 800:block">
              <img
                src="/zombie_c.png"
                alt="zombie cereal"
                className="w-4/12 c-r-s inline-block mt-10"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="text-white text-24 800:text-30 relative w-full mt-10 mb-8 pb-4 800:mt-4">
            <div className="absolute right-0 -top-52 hidden 800:block flex justify-end text-right">
              <img
                src="/spark_c.png"
                alt="spark cereal"
                className="w-1/4 c-s inline-block mt-10"
              />
            </div>
            <BlockContent blocks={about} serializer={Serializer} />
          </div>
          {characters && (
            <div className="800:mt-10">
              <DayInTheLife characters={characters} />
            </div>
          )}
        </div>
      </div>
      <div className="max-w-1000 p-8 mx-auto relative flex justify-between items-start 800:p-2">
        <img
          src="/zombie_head.png"
          alt="zombie head cereal"
          className="w-5/12 600:w-2/12 transform rotate-4 800:w-3/12 relative 800:-top-40 inline-block mb-10"
        />
        <img
          src="/dash_head.png"
          alt="dash head cereal"
          className="w-4/12 600:w-2/12 transform rotate-2 800:absolute right-0 -bottom-20 inline-block"
        />
      </div>
      <div className="max-w-1000 p-8 mx-auto relative">
        <Box
          cereal="/dash-c-small.png"
          title="Founder Story"
          classNames="bg-zombie"
          wrapperClass="max-w-600 bg-white relative 1000:-left-28"
        >
          <div className="p-8">
            <BlockContent blocks={founderStory} serializers={Serializer} />
          </div>
        </Box>
        <Box
          cereal="/dash-c-small.png"
          title="eem.jpg"
          classNames="bg-yellow"
          wrapperClass="max-w-400 w-full relative -bottom-10 800:absolute right bottom-20"
        >
          <Image
            imageId={founderImage.asset._id}
            className="w-full"
            alt={"photo of emily"}
          />
        </Box>
      </div>
      <div className="max-w-1000 p-8 mx-auto relative 800:h-20 flex mt-6 800:mt-0 justify-between items-start 800:p-2 z-10">
        <img
          src="/spark_head.png"
          alt="spark head cereal"
          className="w-4/12 600:w-2/12 transform rotate-4 800:w-2/12  800:left-20 relative 800:top-20 inline-block mb-10"
        />
        <img
          src="/flex_head.png"
          alt="flex head cereal"
          className="w-4/12 600:w-2/12 800:hidden transform rotate-2 800:absolute right-0 -bottom-20 inline-block"
        />
      </div>
      <div className="max-w-1400 p-8 mx-auto 800:mt-20 800:mb-80 relative">
        <Box
          cereal="/dash-c-small.png"
          title="Giving Back"
          classNames="bg-zombie"
          wrapperClass="bg-black text-white"
        >
          <div className="p-8">
            <div className="grid grid-cols-1 800:grid-cols-2 800:gap-20 800:p-10">
              <BlockContent blocks={givingBack} serializers={Serializer} />
              <div className="mt-6 800:mt-0">
                <Image
                  imageId={givingBackImage.asset._id}
                  className="w-full rounded-3xl overflow-hidden"
                  alt="giving back"
                />
              </div>
            </div>
          </div>
        </Box>
        <Box
          cereal="/dash-c-small.png"
          title="collabs.jpg"
          classNames="bg-blue"
          wrapperClass="bg-black mt-10 800:absolute w-full 800:left-20 800:-top-72 max-w-400 text-white"
        >
          <div>
            <div className="w-full keen-slider overflow-hidden" ref={sliderRef}>
              {collabs.map(({ name, image }, i) => (
                <div className="w-full flex keen-slider__slide" key={i}>
                  <Image
                    imageId={image.asset._id}
                    className="w-full"
                    alt={"slideshow images"}
                  />
                </div>
              ))}
            </div>
            <div className="border-t-2 border-solid border-black text-black bg-blue flex items-center justify-between">
              <span className="mono p-2">{collabs[active].name}</span>
              <div className="p-2 flex items-center">
                <button className="mr-4" onClick={() => slider.next()}>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.08984 12.1826L2.00491 7.09145L7.08984 2.00028"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button onClick={() => slider.prev()}>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.78125 2.00293L11.8662 7.0941L6.78125 12.1853"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </Box>
        <div className="max-w-1000 p-8 mx-auto relative 800:h-20 flex justify-between items-start 800:p-2 z-10">
          <img
            src="/flex_head.png"
            alt="flex head cereal"
            className="w-4/12 600:w-3/12 hidden 800:block transform rotate-2 800:absolute right-0 -bottom-40 inline-block"
          />
        </div>
      </div>
      {(sustainabilityStory || sustainabilityImage) && (
        <div className="max-w-1400 p-8 mx-auto 800:mt-20 relative">
          <Box
            cereal="/dash-c-small.png"
            title="Sustainability"
            classNames="bg-zombie"
            wrapperClass="bg-black text-white"
          >
            <div className="p-8">
              <div className="grid grid-cols-1 800:grid-cols-2 800:gap-20 800:p-10">
                <BlockContent
                  blocks={sustainabilityStory}
                  serializers={Serializer}
                />
                <div className="mt-6 800:mt-0">
                  <Image
                    imageId={sustainabilityImage.asset._id}
                    className="w-full rounded-3xl overflow-hidden"
                    alt="giving back"
                  />
                </div>
              </div>
            </div>
          </Box>
        </div>
      )}  
      <div className="pt-20 pb-20 800:pt-40">
        <Marqy className="text-white offlimits text-48 800:text-80 uppercase">
          {marqueeText.map(m => (
            <span>{m}</span>
          ))}
        </Marqy>
      </div>
    </div>
  );
};

import React, { useState, useEffect} from 'react'
import Draggable from 'react-draggable'

import cx from 'classnames'

import {
  Close
} from 'src/components/svgs'

const TIME = 10

export const PopupGame = ({ cerealImage, character }: {
  cerealImage: {
    asset: {
      url: string
    }
  }
  character: string
}) => {
  const [gamestart, setGamestart] = useState(false)
  const [time, setTime] = useState(TIME)
  const [answers, setAnswers] = useState([])
  const emptyArray = [...Array(20)]
  const [timerStart, setTimerStart] = useState(false)
  useEffect(() => {
    if (!timerStart) return
    const intervalId = setInterval(() => {
      setTime(time - 1);
    }, 1000);

    if (time === 0) {
      clearInterval(intervalId)
      setGamestart(false)
      setTimerStart(false)
      setTime(TIME)
    }
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [timerStart, time])

  const clickStart = () => {
    setGamestart(true)
    setTime(TIME)
    setAnswers([])
    setTimerStart(true)
  }

  const setClick = (index: number) => {
    if (gamestart) {
      setAnswers([
        ...answers,
        index
      ])
    }
  }

  const checkClicked = (index: number) => {
    return answers.includes(index)
  }

  return (
    <Draggable
      defaultPosition={{x: 10, y: 100}}>
      <div className='popup__game abs z3 bcy box-shadow ba'>
        {/* <button type='reset' className='bcy close abs m1 top right p05 bab z2 cb'>
          <Close className='block' />
        </button> */}
        <div className='p1 popup__game-window f jcb ais fw'>
          {emptyArray.map((item, i) => (
            <img key={i} onClick={() => setClick(i)} className={cx('x', {
              'clicked': checkClicked(i)
            })} src={`${cerealImage.asset.url}?h=100`} alt='cereal image' />
          ))}
        </div>
        <div className='p1 ac'>
          <h4 className='caps offlimits'>
            {!gamestart ? 'Click all Cereal in 10 seconds' : (
              <span>{time}s left</span>
            )}</h4>
        </div>
        <div>
          <button onClick={clickStart} type='submit' className={cx('p1 s1 button--black button--lg x s20 button', {
            'game-active': gamestart
          })}><span>{gamestart ? 'Reset' : 'Go'}</span></button>
        </div>
      </div>
    </Draggable>
  )
}
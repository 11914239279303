import React from "react"
import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"

import { Image } from 'src/components/image'

// import {
//   Bubble
// } from 'src/components/svgs'

export interface FloatingHeadProps {
  data: {
    headImage: {
      asset: {
        id: string
      }
    }
    text: []
  }
}

export const FloatingHead = ({ data }: FloatingHeadProps) => {
  const { text, headImage: { asset: { id } } } = data
  return (
    <div className="container--xl ar outer mxa py2 f jce rel z4 floating px1 x">
      <div className='floating__inner x'>
        <div className='abs floating__text z1'>
          <div className='abs x ac y top left f floating__text-inner jcc aic px1'>
            {text && (<BlockContent blocks={text} serializers={Serializer} />)}
          </div>
        </div>
        <Image imageId={id} alt='floating head' className='x floating__head' />
      </div>
    </div>
  )
}
import React, { useEffect, useState, useRef } from "react"
import cx from 'classnames'
import EmblaCarouselReact from 'embla-carousel-react'


import { useStore, useAddStar } from 'src/context/siteContext'
import { MarioStar } from 'src/components/star'

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const CerealBites = ({time, className}: {time: number, className: string}) => {
  // const [count, setCount] = useState(time);
  // useInterval(() => {
  //   if (count > 99) {
  //     setCount(0);
  //   } else {
  //     setCount(count + 1)
  //   }
  // }, 200);
  return (
    <div className={cx('cereal__bites abs', className)}>
      <img src='/c_whole-1.png' alt='Cereal Piece' className={cx('abs show')} />
    </div>
  )
}

export interface PressModuleProps {
  data: {
    title?: string
    press: Array<{
      _id: string
      logo: {
        asset: {
          url: string
        }
      }
      quote: string
      colorAssociation: string
      linkOut: string
    }>
  }
}

export const PressModule = ({ data }: PressModuleProps) => {
  const {
    title,
    press
  } = data
  
  const head = React.createRef()

  const { stars } = useStore()
  const addStar = useAddStar()

  const [embla, setEmbla] = useState(null)
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (embla) {
      embla.on('select', () => {
        setIndex(embla.selectedScrollSnap())
      })
    }
  }, [embla])
  return (
    <div className='bcw press__module outer ac p1'>
      <div className="container--xl  mxa py2 x">
        <div className='container--1000 press__header rel mxa'>
          <MarioStar className='press__star' starName='pressStar' />
          <CerealBites className='one' time={14} />
          <CerealBites className='two' time={6} />
          <CerealBites className='three' time={-4} />
          <CerealBites className='four' time={-12} />
          <CerealBites className='five' time={-20} />
          <CerealBites className='six' time={-30} />
          <h4 className='s-desktop caps offlimits'>{title}</h4>
        </div>
        <EmblaCarouselReact
          emblaRef={setEmbla}
          options={{ loop: true }}>
            <div className='x f press-slideshow embla__container'>
              {press.map(({
                _id,
                quote,
                linkOut,
                logo,
                colorAssociation
              }, i) => (
                <div className='press__slide embla__slide x ac p1' key={i}>
                  <div className='x ba box-shadow-sm bcw rel'>
                  <div className={cx('p1 press__slide-head bb', colorAssociation)}>
                    <div className='press__slide-logo mxa'>
                      <img src={logo.asset.url} className='inline-block' alt={quote} />
                    </div>
                  </div>
                    <div className='container--m mxa p1'>
                      <p>{quote}</p>
                      {linkOut && (<a href={linkOut} target='_blank' className='s1 inline-block  mb1 underline-extra mt1'>Read More</a>)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
        </EmblaCarouselReact>
        <div className='review__dots press-dots mt1 f jcc aic'>
          {press.map((item, i) => (
            <div key={i} className={cx('review__dots-single bcb m05', {
              'active': index === i
            })} />
          ))}
        </div>
        <div className='container--xl outer mxa'>
          <div className='press__desktop mt1 ba  rel z5 x box-shadow rel'>
            <div className='f jcb aic'>
              {press.map(({
                quote,
                logo,
                colorAssociation
              }, i) => (
                <div className={cx('press__desktop-logo rel x ac', {
                  'active': index === i
                })} onClick={() => setIndex(i)} key={i}>
                  <div className={cx('p1 f jcc aic press__desktop-logo-img', colorAssociation)}>
                    <div className='mxa'>
                      <img src={logo.asset.url} className='inline-block' alt={quote} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='press__desktop-quote'>
              {press.map(({
                quote,
                linkOut,
                colorAssociation
              }, i) => (
                <div className='press__desktop-quote x ac' key={i}>
                  <div className={cx('p1 container--m press__desktop-quote-single mxa', {
                    'active': index === i
                  })}>
                    <div className='mxa'>
                      <h4>{quote}</h4>
                      {linkOut && (<a href={linkOut} target='_blank' className='s20 inline-block mt1 pt1 underline-extra mt1'>Read More</a>)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
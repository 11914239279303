import React, { useState, useEffect } from 'react'
import vsbl from 'vsbl'
import cx from 'classnames'

import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"
import { Image } from 'src/components/image'

export interface ChatrouletteProps {
  data: {
    age: string
    headImage: {}
    sex: string
    location: string
    scriptResponses: []
  }
  main: {
    slug: {
      current: string
    }
  }
}

export const Chatroulette = ({ data, main }: ChatrouletteProps) => {
  const {
    age,
    headImage,
    location,
    sex,
    scriptResponses
  } = data
  const {
    slug
  } = main
  
  return (
    <div>
      <div className='container--xl chat outer mxa py1 al px1 x'>
        <div className='border-2 border-black overflow-hidden border-solid bcw p1 chat__window oh roboto box-shadow-sm'>
          <div className='f fw jcb aist'>
            <div className='chat__image overflow-hidden x ba c50 rel'>
              <div className='p1 z-10 abs bottom x left bcb cw'>
                <div className='chat__stats-row caps f jcb aic'>
                  <span>Age:</span>
                  <span className='chat__line'>.......................................................................</span>
                  <span>{age}</span>
                </div>
                <div className='chat__stats-row caps f jcb aic'>
                  <span>Sex:</span>
                  <span className='chat__line'>.......................................................................</span>
                  <span>{sex}</span>
                </div>
                <div className='chat__stats-row caps f jcb aic'>
                  <span>Location:</span>
                  <span className='chat__line'>.......................................................................</span>
                  <span>{location}</span>
                </div>
              </div>
              <div className={main.colorAssociation}>
                <Image imageId={headImage.asset.id} className={cx('x', slug.current)} alt='Character portrait' />
              </div>
            </div>
            <div className='c50 chat__typed py1'>
              <ChatApp chats={scriptResponses} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Connect = ({ connecting, stopConnecting }: {connecting: boolean, stopConnecting: any}) => {
  const [loading, setLoading] = useState([])
  useEffect(() => {
    if (!connecting) return
    const intervalId = setInterval(() => {
      setLoading([
        ...loading,
        {id: new Date()}
      ])
    }, 500);

    if (loading.length === 8) {
      clearInterval(intervalId);
      stopConnecting()
    }

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [connecting, loading])
  return (
    <div>
      <div className='comp'>Connecting
          <span className='chat__line'>{loading.map((item, i) => (
            <span key={i}>.</span>
          ))}</span>
        </div>
    </div>
  )
}

const ChatApp = ({ chats }) => {
  const [connecting, setConnecting] = useState(false)
  const [connected, setConnected] = useState(false)
  const [connectedMsg, setConnectedMessage] = useState(false)
  const [messages, setMessages] = useState([])
  const [user, setUser] = useState(false)
  const [loading, setLoading] = useState([])
  const [chatValue, setChatValue] = useState(0)
  const form = React.createRef()
  const chatWindow = React.createRef()
  const scrolling = React.createRef()

  useEffect(() => {
    const vs = vsbl(chatWindow.current, { threshold: -0.1})(() => {
      setConnecting(true)
     })
     vs.update()
  }, [connecting])

  useEffect(() => {
    if (connected) {
      showConnected()
    }
  }, [connected])

  const addMessage = (chat) => {
    setMessages([
      ...messages,
      {...chat}
    ])
  }


  useEffect(() => {

    const scroll = scrolling.current
    if (user) {
        if (chats[chatValue] !== undefined) {

          setTimeout(() => {
            setMessages([
              ...messages,
              chats[chatValue]
            ])
            setChatValue(chatValue + 1)
            scroll.scrollTo(0, 6000)
          }, 1000)
        } else {
          setTimeout(() => {
            const newMessage = {
              _key: `${Math.random()}`,
              _type: 'scriptResponses',
              name: `${chats[0].name} Left //`,
              left: true,
              response: [
                {
                  _key: `${Math.random()}`,
                  _type: 'block',
                  style: 'normal',
                  children: [
                    {
                      _type: 'span',
                      text: ''
                    }
                  ]
                }
              ]
            }
            addMessage(newMessage)
            scroll.scrollTo(0, 6000)
          })

        }
        setUser(false)

    }
  }, [messages, user])

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault()
    const {message} = form.current
    setUser(true)
    if (message) {
      const newMessage = {
        _key: `${Math.random()}`,
        _type: 'scriptResponses',
        name: 'hungry human',
        response: [
          {
            _key: `${Math.random()}`,
            _type: 'block',
            style: 'normal',
            children: [
              {
                _type: 'span',
                text: message.value
              }
            ]
          }
        ]
      }
      addMessage(newMessage)
    }
    message.value = ''
    
    if (scrolling) {
      const scroll = scrolling.current
      setTimeout(() => {
        scroll.scrollTo(0, 6000)
      }, 400)
    }
  }

  const showConnected = () => {
    setTimeout(() => {
      setConnectedMessage(true)
    }, 1000)
    setTimeout(() => {
      addMessage(chats[chatValue])
      setChatValue(chatValue + 1)
    }, 2000)
  }
  return (
    <div ref={chatWindow} className='chat__window-flex y f jcb'>
      {!connected ? (
        <Connect connecting={connecting} stopConnecting={() => setConnected(true)} />
      ) : (
        <React.Fragment>
          <div className='chat__window-inner'>
            <div className='bold roboto comp mb1'>Connection request recieved</div>
            {connectedMsg && (
              <span className='italic roboto my05'>> connected, feel free to talk now</span>
            )}
            <div className='chat__window-messages' ref={scrolling}>
              {messages.map((msg, i) => (
                <div className='f jcs ais my05 fw chat__messages' key={i}>
                  <span className={cx('chat__window-name mb1 block roboto bold caps cr', {
                    'italic': msg.left === true
                  })}>{msg.name}:</span>
                  <div className='chat__window-block mt-1'>
                    <BlockContent blocks={msg.response} serializers={Serializer} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <form onSubmit={e => submitForm(e)} ref={form} className='rel chat__window-form'>
            <textarea className='x p1 chat__window-text' name='message' placeholder='Enter text here' />
            <div className='abs z2 bottom right m1 '>
              <button className='button button--red text-black'><span className='text-black'>Chat</span></button>
            </div>
          </form>
        </React.Fragment>
      )}
    </div>
  )
}
import React, { useState, useEffect } from 'react'
// @ts-ignore
import { encode, decode } from 'shopify-gid'
// @ts-ignore 
import vsbl from 'vsbl'
import Draggable from 'react-draggable'
import cx from 'classnames'
import srraf from 'srraf'
import { shopify as storefront, globalID } from "src/shopify/client";
import { productQuery } from "src/shopify/queries";

import { Image } from 'src/components/image'

import { Waitlist } from 'src/components/product/waitlist'

import {
  Close
} from 'src/components/svgs'

import {
  GTMProduct,
  trackAddToCart,
  trackProductDetailView,
} from "src/components/analytics"

import { useAddItemToCart, useStore, useSetNavIsUp } from 'src/context/siteContext'

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
}

export const ProductForm = ({ defaultPrice, badge, type, color, productId, subscription, cerealImage, sub, title, image, tickets = 0 }: {
  defaultPrice: string
  productId: number
  badge?: string
  type?: string
  cerealImage: {
    asset: {
      _id: string
    }
  }
  tickets?: number
  sub?: string
  title: string
  image: {
    asset: {
      url: string
    }
  }
}) => {
  
  
  const addItemToCart = useAddItemToCart()
  const setNavIsUp = useSetNavIsUp()
  const { navIsUp, checkout } = useStore()
  const [purchaseType, setPurchaseType] = useState('onetime')
  const [subPopup, setSubPopup] = useState(false)
  const [price, setPrice] = useState(defaultPrice)
  const [variant, setVariant] = useState(null)
  const [quantity, setQuantity] = useState(1)
  const [available, setAvailable] = useState(false)
  const [activeVariantId, setActiveVariantId] = useState(null as string | null)
  const [check, setCheck] = useState(true)

  const isNFT = type === 'nft'

  useEffect(() => {
    if (check) { 
      storefront
        .fetch(productQuery, {
          id: globalID("Product", productId)
        })
        .then(data => {
          const product = data.product; 
          const variants = product?.variants.edges; 
          const defaultVariant = variants?.[0].node; 

          if (product && defaultVariant) { 
            setActiveVariantId(defaultVariant.id as string);
            setAvailable(defaultVariant.availableForSale);
            setPrice(defaultVariant.price.amount);
            setVariant(defaultVariant); 
            setCheck(false);
          }
        });
    }
  }, [check]); 

  const handleSubmit = (e: React.FormEvent) => {
    const eating = new Audio(`/crunch.mp3`);
    e.preventDefault()
    if (available) {
      eating.volume = 0.4
      eating.play()
      const customAttributes = [
        // {key: '_subTitle', value: purchaseType === 'onetime' ? sub : "Subscription" }, // FIXME: Old with SUBS

        {key: '_subTitle', value: purchaseType === 'onetime' ? sub ? sub : '' : "Subscription" },
        {key: '_image', value: image.asset.url },
        // {key: '_ticketAdd', value: `${purchaseType === 'onetime' ? tickets : tickets * 2}` },
        {key: '_color', value: color },
      ]

      if (purchaseType === 'monthly') {
        customAttributes.push({key: '_isSubscription', value: JSON.stringify(subscription, null, 4)})
      }
      console.log('custom', customAttributes)
      addItemToCart(activeVariantId, quantity, customAttributes).then(() => {
        trackAddToCart(
          {
            name: title,
            id: activeVariantId || "error",
            price: parseFloat(price),
            variant: variant?.title || "error",
          },
          quantity,
          checkout
        )
      })
    }
  }

  useEffect(() => {
    if (activeVariantId !== null && variant) {
      const googleTagManagerProduct: GTMProduct = {
        name: title,
        id: activeVariantId || "error", // SKU!
        price: parseFloat(variant?.price || ""), // string
        variant: variant?.title || "error",
      }

      trackProductDetailView(
        [googleTagManagerProduct],
        `${title} -- ${variant?.title || "variant_title_error"}`
      )
    }
  }, [activeVariantId, variant])

  const outOfStock = !check && !available && (
    <div className={cx('border-t-2 w-full')}>
        <div className={`pl-6 pt-4 pb-4  bg-blue border-b-2 f jcb aic js-header-wait`}>
          <span className='text-18'>Out of Stock</span>
        </div>
        <div className='popup-items al pl-6 pt-4 pb-4 bcw'>
          <h5 className='text-18'>Get notifed when stock is replenished.</h5>
          <div className=''>
            <Waitlist
              accountId='KyGk9C'
              message="Got it! We'll update you when it's back."
              buttonText='Notify Me'
              variantId={activeVariantId} />
          </div>
      </div>
    </div>
  )    

  return (
    <React.Fragment>
      <div className='variety__form box-shadow-sm border-2 border-black border-solid bcw'>
        <form onSubmit={(e) => handleSubmit(e)}>
        {!isNFT && ( <div className='flex justify-between items-center p-4'>
            <p className='bold m-0 p-0 text-18 pl-2'>${parseFloat(price).toFixed(2)}</p>
            {badge && (
              <span className='bg-yellow text-black p-2 rounded-md uppercase mono'>{badge}</span>
            )}
          </div>)}
          {/* TICKET SYSTEM 
            <div className='f jcs aic variety__tickets'>
              <p className='bold m0 pb05 ml05'>${parseFloat(price)} (2 Boxes) - {purchaseType === 'onetime' ? tickets : tickets * 2} Tickets</p><img src='/mini-ticket.png' className='ml05 product__form-tickets' alt='ticket image' />
            </div>
          */}
          {available && !check ? (
            <React.Fragment>
              {isNFT ? (
                <div className='s24 grid grid-cols-6'>
                  <div className='product__form-button nft__form col-span-6'>
                    <button type='submit'  className='p1 s1 js-nft button--black m0 button--border s20 button'><span>Claim Your NFT Cereal Toy</span></button>
                  </div>
                </div>
              ) : (
                <div className='s24 grid grid-cols-6'>
                  <div className='flex border-t-2 col-span-2 border-black'>
                    <div className='flex items-center w-full justify-between p-2 pl-4 pr-4'>
                      <button type='button' className='block rel mr05 qty__control h-12 w-2/12 b-unset no-style s24 founders cursor p-1 aic' onClick={() => quantity === 1 ? null : setQuantity(quantity - 1)}>
                        <img className='w-full h-12 block' src='/qty_minus.png' alt='minus' />
                      </button>
                      <input type='number'
                        value={quantity} onChange={e => setQuantity(parseInt(e.currentTarget.value, 10))} 
                        name='quantity' min='1' className='cb w-4/12 founders border-none card-qty bn ac' />
                      <button type='button' className='qty__control h-12 w-2/12 b-unset no-style s1 block  founders s24 cursor rel p-1 jcc aic' onClick={() => setQuantity(quantity + 1)}>
                        <img className='w-full h-12 block' src='/qty_plus.png' alt='plus' />
                      </button>
                    </div>
                  </div>
                  <div className='product__form-button col-span-4'>
                    <button type='submit' className='p1 s1 button--black m0 button--border s20 button'><span>Add to Cart</span></button>
                  </div>
                </div>
              )}
            </React.Fragment>
          ): (
            <React.Fragment>
              {available && !isNFT && (
                <span>Checking Stock</span>
              )}
            </React.Fragment>
          )}

        </form>
        {outOfStock}
      </div>
    </React.Fragment>
  )
}
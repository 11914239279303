import React, { useState, useEffect } from 'react'
import Draggable from 'react-draggable'
 
import { shopify as storefront, globalID } from "src/shopify/client";
import { productQuery } from "src/shopify/queries";

import {
  Close
} from 'src/components/svgs'

import { useAddItemToCart } from 'context/siteContext'

export const PopupQuickAdd = ({ productId, cerealImage, sub, tickets }: {
  productId: number
  cerealImage: {
    asset: {
      _id: string
    }
  }
  tickets: string
  sub: string
}) => {
  const addItemToCart = useAddItemToCart()

  const [quantity, setQuantity] = useState(1)
  const [available, setAvailable] = useState(false)
  const [activeVariantId, setActiveVariantId] = useState(null as string | null)
  const [check, setCheck] = useState(true)

  useEffect(() => {
    if (check) { 
      storefront
        .fetch(productQuery, {
          id: globalID("Product", productId)
        })
        .then(data => {
          const product = data.product; 
          const variants = product?.variants.edges; 
          const defaultVariant = variants?.[0].node; 

          if (product && defaultVariant) { 
            setActiveVariantId(defaultVariant.id as string);
            setAvailable(defaultVariant.availableForSale); 
            setCheck(false);
          }
        });
    }
  }, [check]);


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (available) {
      const customAttributes = [
        {key: '_subTitle', value: sub },
        {key: '_image', value: cerealImage.asset.url },
        {key: '_ticketAdd', value: `${tickets}` },
      ]
      addItemToCart(activeVariantId, quantity, customAttributes)
    }
  }

  return (
    <Draggable
      defaultPosition={{x: 60, y: -100}}>
      <div className='popup__quick ac oh rel z3 bclb box-shadow ba'>
        {/* <button type='reset' className='bcy close abs m1 top right p05 bab z2 cb'>
          <Close className='block' />
        </button> */}
        <div className='popup__cereal-box-1 abs bottom left'>
          <img src={`${cerealImage.asset.url}?h=200`} />
        </div>
        <div className='popup__cereal-box-2 abs right top'>
          <img src={`${cerealImage.asset.url}?h=200`} />
        </div>
        <div className='p1 rel z1'>
          <h3 className='caps pt1 offlimits'>Buy Now and get 0% off</h3>
          <form className='py1 mt1 mb05' onSubmit={(e) => handleSubmit(e)}>
            {available && !check ? (
              <div className='product__form-button'>
                <button type='submit' className='p1 s1 button--black button--border button--auto s20 button'><span>Add to Cart</span></button>
              </div>
            ) : (
              <div>
                {available ? (
                  <span>Checking Stock</span>
                ): (
                  <span>Unavailable</span>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </Draggable>
  )
}
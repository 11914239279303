import React, { useState } from "react"
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'

import BlockContent from "@sanity/block-content-to-react"

import { Image } from 'src/components/image'
import { Serializer } from "src/utils/serializer"
import { DayInTheLife } from "src/components/global/dayInLife"

export interface PressStoryProps {
  data: {
    text: any[]
  }
}

export const PressStory = ({ data }: PressStoryProps) => {
  const { cerealImage, characters, press, text } = data
  const [slide, setSlide] = useState(1)
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    slideChanged(s) {
      setSlide(s.details().relativeSlide + 1)
    },
  })

  return (
    <div className='bg-gray overflow-hidden p-5 pt-8 pb-8 800:p-12 800:pt-40 800:pb-20 relative'>
      <div className='absolute -right-40 -top-40 hidden 800:block'>
        <img src='/dash_c.png' className='c-r-s' alt='dash cereal' />
      </div>
      <div className='absolute -left-60 top-80 hidden 800:block'>
        <img src='/zombie_c.png' alt='dash cereal' className='w-1/2 c-s' />
      </div>
      <div className='grid pt-6 800:pt-0 relative z-10 grid-cols-1 800:grid-cols-2 800:gap-20 1200:gap-48'>
        <div className='flex flex-col-reverse 800:flex-col justify-between'>
          <div className='mt-6 mb-4 800:mt-0 800:mb-0'>
            <BlockContent blocks={text} serializers={Serializer} />
          </div>
          <div className='box-shadow-sm mb-6 800:mb-0 border-2 border-solid 800:mt-10'>
            <div className='bg-black p-2'>
              <span className='text-white mono uppercase'>Highlight: Press</span>
            </div>
            <div className='bg-white p-8 text-center'>
              <div className='w-full keen-slider rounded-3xl overflow-hidden' ref={sliderRef}>
                {press.map(({
                  logo,
                  title,
                  quote
                }, i) => (
                  <div className='w-full text-center flex keen-slider__slide' key={i}>
                    <div className='w-full'>
                      <Image imageId={logo.asset._id} className='w-3/12 mx-auto' alt={title} />
                      <p className='text-18 w-full 1200:w-10/12 mx-auto 800:text-24'>{quote}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className='w-36 mx-auto mt-8 inline-block'>
                <div className='flex w-items-center justify-between rounded-full bg-black'>
                  <button onClick={() => slider.prev()} className='bg-yellow hover:bg-opacity-75 h-10 w-10 border-2 border-solid border-black rounded-full flex items-center justify-center'>
                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                      <title>Previous Press item</title>
                      <path d="M4.83008 7.35547L2.18725 4.7094L4.83008 2.06333" stroke="#24211E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                  <span className='text-20 relative top-1 leading-tight text-white'>{slide}/{press.length}</span>
                  <button onClick={() => slider.next()} className='bg-yellow hover:bg-opacity-75 h-10 w-10 border-2 border-solid border-black rounded-full flex items-center justify-center'>
                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                      <title>Next press item</title>
                      <path d="M4.11035 2.06445L6.75318 4.71052L4.11035 7.35659" stroke="#24211E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DayInTheLife characters={characters} />
        </div>
      </div>
    </div>
  )
}
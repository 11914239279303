import React from "react"

import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"

export interface StandardHeroProps {
  data: {
    text: any[]
  }
}

export const StandardHero = ({ data }: StandardHeroProps) => {
  const { text } = data
  return (
    <div className='bg-gray'>
      <div className="container--xl standard hero__standard outer mxa py2 ac p1 x">
        <div className='container--1000 mxa'>
          {text && (<BlockContent blocks={text} serializers={Serializer} />)}
        </div>
      </div>
    </div>
  )
}
import React from 'react'
import Draggable from 'react-draggable'

import { Newsletter } from 'src/components/newsletter'


import {
  Close
} from 'src/components/svgs'

export const PopupNewsletter = () => {
  return (
    <Draggable 
    defaultPosition={{x: 120, y: -100}}>
      <div className='rel z3 bcw popup__newsletter abs box-shadow ba'>
        {/* <button type='reset' className='bcy close abs m1 top right p05 bab z2 cb'>
          <Close className='block' />
        </button> */}
        <div className='p1 px2'>
          <h4 className='caps pt1 offlimits'>
            Receive updates from our emotionally unstable cartoons.
          </h4>
          <Newsletter arrowClass='cb' />
          <p>Subscribe a friend, your mom, your roommate, or your ex.</p>
        </div>
      </div>
    </Draggable>
  )
}
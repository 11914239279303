import React from 'react'
import cx from 'classnames'

import { PopupSpinner } from 'src/components/popups/spinner'
import { PopupNewsletter } from 'src/components/popups/newsletter'
import { PopupMarquee } from 'src/components/popups/marquee'
import { PopupQuickAdd } from 'src/components/popups/quickAdd'
import { PopupInfinite } from 'src/components/popups/infinite'
import { PopupGame } from 'src/components/popups/game'

export interface PopupZoneProps {
  data: {}
  main: {
    subTitle: string
    slug: {
      current: string
    }
    cerealImage: {
      asset: {
        _id: string
      }
    }
    colorAssociation: string
  }
  shopify: {
    productId: string
  }
}

export const PopupZone = ({ main, shopify }: PopupZoneProps) => {
  const {
    colorAssociation,
    slug: {
      current
    }
  } = main
  return (
    <div className={cx('popup__zone rel', colorAssociation)}>
      <div className='container--xl outer mxa py2 p1 x rel'>
        <PopupSpinner />
        <PopupQuickAdd {...shopify} cerealImage={main.mainImage} tickets={main.ticketValue} sub={main.subTitle} />
        <PopupNewsletter />
        <PopupMarquee character={current} />
        <PopupInfinite cerealImage={main.cerealImage} character={current} />
        <PopupGame cerealImage={main.cerealImage} />
      </div>
    </div>
  )
}
import React, { useState, useEffect, useRef } from "react"
import cx from 'classnames'
import BlockContent from "@sanity/block-content-to-react"
// @ts-ignore
import { encode, decode } from 'shopify-gid'
// @ts-ignore
import shopify from 'api/shopify'

import { Image } from 'src/components/image'
import { Serializer } from "src/utils/serializer"


import { useAddItemsToCart } from 'src/context/siteContext'

export interface BundleBuilderProps {
  data: {
    text: any[]
  }
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const ImageMap = ({ image, title, quantity }) => {
  const imageArray = new Array(quantity).fill(quantity)

  return (
    <div className='w-full col-span-2 800:w-3/4 1000:w-8/12 800:mb-2d 800:mx-auto relative z-10'>
      {imageArray.map((empty, i) => {

        let z = 100 - i
        let stylePosition = {}
        if (i % 2 && i > 0) {
          stylePosition = { transform: `translate(-50%, -50%)`, marginLeft: `${i * 12}px`,  zIndex: z }
        } else if (i > 0) {
          stylePosition = { transform: `translate(-50%, -50%)`, marginLeft: `-${i * 12}px`,  zIndex: z }
        }
        return (
          <Image key={i} imageId={image} className={cx('w-full', i === 0 && 'relative z10', i > 0 && 'hidden 800:block w-10/12 h-10/12 top-1/2 left-1/2 absolute')} style={stylePosition} alt={`${title} Cereal`} />
        )}
      )}
    </div>
  )
}

export const BundleBuilder = ({ data }: BundleBuilderProps) => {
  const { text, products } = data

  const addItemsToCart = useAddItemsToCart()
  const [check, setCheck] = useState(true)
  const [activeProducts, setActiveProducts] = useState([])

  const activePrice = activeProducts.reduce((accumulator, item) => {
    return accumulator + (item.quantity * parseFloat(item.price))
  }, 0)


  const updateVariant = (productId, variantId) => {
    const updatedItems = activeProducts.map(item => {
      if (item.productId === productId) {
        return {
          ...item,
          variantId
        }
      } else { return {...item } }
    })
    setActiveProducts(updatedItems)
  }


  useEffect( () => {
    const stateProducts = []
    if (check) {

      products.forEach(item => {
        const shopifyId = encode("Product", item.content.shopify.productId, {
          accessToken: "c422a19a24242fb3a7aa6296dfab54da",
        })
  
        shopify.product.fetch(shopifyId).then((product: any) => {
          const decodedVariants = [] as any
          product.variants.forEach((productVariant: any) => {
            decodedVariants.push({
              ...productVariant,
              cleanId: parseInt(decode(productVariant.id).id, 0),
            })
          })
          stateProducts.push({
            variantId: decodedVariants[0].id,
            name: item.content.main.cleanTitle,
            subTitle: item.content.main.subTitle,
            color: item.content.main.colorAssociation,
            productId: item.content.shopify.productId,
            image: item.content.main.singleImage,
            quantity: 0,
            price: item.content.shopify.defaultPrice
          })

        })
        
      })
      setTimeout(() => {
        setActiveProducts(stateProducts)
      }, 400)
      setCheck(false)
    }
  }, [check])

  const handleSubmit = (e) => {
    e.preventDefault()
    const itemsToAdd = []
    activeProducts.forEach(item => {
      if (item.quantity > 0) {
        itemsToAdd.push({
          variantId: item.variantId,
          quantity: item.quantity,
          customAttributes: [
            {key: '_subTitle', value: item.subTitle},
            {key: '_image', value: item.image.asset.url },
            {key: '_color', value: item.color },
          ]
        })
      }
    })
    console.log('items color', itemsToAdd)
    addItemsToCart(itemsToAdd)
  }

  const productEngagement = (
    <div>
      <div className='flex justify-between flex-wrap 600:flex-nowrap'>
        {activeProducts.map(item => (
          <div className='mono uppercase w-1/2 flex items-center text-14' key={item.productId}>
            <span className={cx('h-4 w-4 mr-2 inline-block rounded-full', item.color)} />
            <span>{item.name}</span> - <span>{item.quantity}X</span>
          </div>
        ))}
      </div>
      <form onSubmit={e => handleSubmit(e)}>
        <button type='submit' className='bg-zombie w-full p-4 box-shadow-sm-b ac button--red text-black mt-4 block pl-8 pr-8 s20 rounded-none button--border'>Add to Cart  - ${activePrice}</button>
      </form>
    </div>
  )

  const toggleQty = (productId, qty) => {
    const updatedItems = activeProducts.map(item => {
      if (item.productId === productId) {
        const quantity = item.quantity + qty >= 0 ? item.quantity+ qty : 0
        return {
          ...item,
          quantity
        }
      } else {
        return {
          ...item
        }
      }
    })
    setActiveProducts(updatedItems)
  }

  return (
    <div className='bg-black grid grid-cols-1 border-b-2 border-solid 1000:grid-cols-2'>
      <div className='relative h-full p-6 pt-30 pb-0 800:pb-20 800:pt-10'>
        <div className={cx('800:p-8 max-w-600 mx-auto h-full text-center flex text-white items-center justify-center')}>
          <div>
            <BlockContent blocks={text} serializers={Serializer} />
            <div className='hidden 800:block mt-10'>
              {productEngagement}
            </div>
          </div>
        </div>
      </div>
      <div className='p-6 1000:p-0'>
        <div className='grid grid-cols-1 800:grid-cols-2'>
          {activeProducts && activeProducts.map(({ quantity, color, image, price, subTitle, productId, name }, i) => (
            <div key={i} className={cx('col-span-1 overflow-hidden border-b-2 800:border-b-0 border-black p-2 800:p-6 800:flex 800:justify-center', color)}>
              <div className='grid gap-2 grid-cols-12 800:block 800:text-center w-full'>
                {quantity > 0 ?
                  <ImageMap image={image.asset._id} title={name} quantity={quantity} />
                :  <img className='col-span-2 w-full 800:w-3/4 1000:w-8/12 800:mb-2d 800:mx-auto' src='empty-box.png' alt='empty box graphic' />}
                <div className='col-span-8 relative z-20 800:flex items-center w-full'>
                  <div className='h-full flex items-center w-full'>
                    <div className='w-full'>
                      <h3 className='text-30 leading-28 offlimits 800:text-40 800:leading-40 uppercase text-white'>{name}
                        {quantity > 0 && (<span className='800:hidden'> - {quantity}X</span>)}
                        <br /><span className='text-black'>{subTitle}</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className='col-span-2 w-full relative z-20'>
                  <div className='flex 800:hidden h-full items-center justify-end pr-2'>
                    <div className='border-2 border-black rounded-md '>
                      <button type='button' onClick={() => toggleQty(productId, 1)} className='flex items-center justify-center border-b-2 border-solid h-8 w-8'>
                        <img src='/qty_plus.png' alt='minus' className='w-1/2' />
                      </button>
                      <button type='button' onClick={() => toggleQty(productId, -1)}  className='flex items-center justify-center h-8 w-8'>
                        <img src='/qty_minus.png' alt='minus' className='w-1/2' />
                      </button>
                    </div>
                  </div>
                  <div className='hidden 800:flex items-center justify-center'>
                    <div className='border-2 flex  items-center border-black rounded-3xl '>
                      <button type='button' onClick={() => toggleQty(productId, -1)}  className='flex items-center justify-center h-10 w-10'>
                        <img src='/qty_minus.png' alt='minus' className='w-1/4' />
                      </button>
                      <span className='text-18 inline-block mr-6 ml-6'>{quantity && quantity}</span>
                      <button type='button' onClick={() => toggleQty(productId, 1)} className='flex items-center justify-center border-solid h-10 w-10'>
                        <img src='/qty_plus.png' alt='minus' className='w-1/4' />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className=' 800:hidden text-white mt-10 mb-6'>
          {productEngagement}
        </div>
      </div>
    </div>
  )
}
import React, { useState } from 'react'
import cx from 'classnames'

import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"

const ActiveDay = ({ character: { id, content: { main: {cleanTitle, characterBio, colorAssociation, characterVideo}} } }) => {
  return (
    <div key={id}>
      <div className={cx('day__video relative overflow-hidden', colorAssociation)}>
        <video className='w-full absolute object-fit h-full' src={characterVideo} playsInline autoPlay loop muted />
      </div>
      <div className={cx('border-t-2 border-solid mono regular text-14 p-6 pb-20', colorAssociation)}>
        <BlockContent blocks={characterBio} serializers={Serializer} />
      </div>
    </div>
  )
}

export const DayInTheLife = ({
  characters
}) => {
  const [active, setActive] = useState(0)

  const characterButtons = characters.map(({id, content: {main: {cleanTitle, colorAssociation}}}, i) => (
   <button key={id} className={cx('rounded-none p-2 text-black uppercase mono border-t-2 border-solid border-black', `hover:${colorAssociation}`, active === i && colorAssociation, {
     'border-l-2': i % 2,
     'bg-white': i !== active,
     '800:border-l-2': i !== 0
   })} onClick={() => setActive(i)}>
     {cleanTitle}
   </button>
 ))
  return (
    <div className='border-2 border-black box-shadow-sm w-full mt-10 800:mt-0'>
      <div className='bg-black text-white mono uppercase p-2'>
        <span>Play: Day in the Life</span>
      </div>
      <ActiveDay character={characters[active]} />
      <div className='grid grid-cols-2 800:grid-cols-4'>
        {characterButtons}
      </div>
    </div>
  )
}
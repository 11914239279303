import React, { useState } from "react"
import cx from 'classnames'
import BlockContent from "@sanity/block-content-to-react"

import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'

import { Image } from 'src/components/image'
import { Serializer } from "src/utils/serializer"

import { Glitter } from 'src/components/svgs'

const TextBlock = ({
  text,
  special,
  alignment
}) => {
  return (
    <div className='relative h-full two__column mt-8 800:mt-0 dpt-6 pb-8'>
      <div className={cx('800:p-8 max-w-600 h-full flex', alignment, {
        'mx-auto': alignment === 'items-center'
        
      })}>
        <BlockContent blocks={text} serializers={Serializer} />
      </div>
    </div>
  )
}

const ColumnVideo = ({
  video,
  badge,
  showStars
}) => {
  return (
    <div className='flex items-end mt-14 800:mt-0 relative justify-end'>
      {showStars && (
        <div className='absolute 800:left-14 top-14'>
          <Glitter className='text-blue relative top-10 w-1/2' />
          <Glitter className='text-green relative left-4 -top-2' />
        </div>
      )}
      {badge && (
        <Image imageId={badge.asset._id} className='absolute w-40 h-40 -top-14 right-10' alt={'badge text'} />
      )}
      <video src={video} playsInline autoPlay loop muted className='rounded-3xl border-2 border-black overflow-hidden 1000:w-10/12' />
    </div>
  )
}

const ColumnSlideshow = ({
  images
}) => {
  const [slide, setSlide] = useState(1)
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    slideChanged(s) {
      setSlide(s.details().relativeSlide + 1)
    },
  })
  return (
    <div className='relative'>
      <div className='w-full keen-slider' ref={sliderRef}>
        {images.map(({
          asset
        }, i) => (
          <div className='w-full flex keen-slider__slide  rounded-3xl overflow-hidden' key={i}>
            <Image imageId={asset._id} className='w-full' alt={'slideshow images'} />
          </div>
        ))}
      </div>
      <div className='absolute w-full z-10 bottom-0 p-4 left text-center 800:text-right'>
        <div className='w-40 mx-auto 800:ml-1 inline-block'>
          <div className='flex items-center justify-between rounded-full bg-black'>
            <button onClick={() => slider.prev()} className='bg-yellow h-10 w-10 border-2 border-solid border-black rounded-full flex items-center justify-center'>
              <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                <title>Previous Slide</title>
                <path d="M4.83008 7.35547L2.18725 4.7094L4.83008 2.06333" stroke="#24211E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
            <span className='text-18'>{slide}/{images.length}</span>
            <button onClick={() => slider.next()} className='bg-yellow h-10 w-10 border-2 border-solid border-black rounded-full flex items-center justify-center'>
              <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                <title>Next Slide</title>
                <path d="M4.11035 2.06445L6.75318 4.71052L4.11035 7.35659" stroke="#24211E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export interface TwoColumnProps {
  data: {
    columns: any[]
    backgroundColor: string
    flexDirection: string
    alignment: string
    special: string
    layoutWidth: string
  }
}


export const TwoColumn = ({ data }: TwoColumnProps) => {
  const { backgroundColor, special, layoutWidth, columns, flexDirection, alignment } = data
  return (
    <div id='two-column' className={cx('p-5', backgroundColor, {
      'text-white': backgroundColor === 'bg-black'
    })}>
      <div className={cx('flex grid two__column grid-cols-1 1000:grid-cols-2', flexDirection, {
        'max-w-1200 800:pt-20 800:pb-20 mx-auto': layoutWidth === 'inline'
      })}>
        {columns.map((col) => (
          <div key={col._key} className=''>
            {col._type === 'standardText' && (
              <TextBlock {...col} special={special} alignment={alignment} />
            )}
            {col._type === 'columnSlideshow' && (
              <ColumnSlideshow {...col} />
            )}
            {col._type === 'columnVideo' && (
              <ColumnVideo {...col} />
            )}
            {col._type === 'image' && (
              <Image imageId={col.asset._id} className='rounded-3xl overflow-hidden border-2 border-black' alt='cereal image' />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
import React, {useEffect, useRef, useState} from "react"

import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'

import { Image } from 'src/components/image'

import { PopupInfinite } from 'src/components/popups/infinite'

export interface ProductInstagramProps {
  data: {
    images: any[]
    video?: string
    title: string
  }
}

const VideoSlide = ({ videoUrl, posterImage, active }) => {
  const videoRef = useRef()
  useEffect(() => {
    const video = videoRef.current
    if (active && video) {
      video.muted = true
      video.play()
    } else {
      video.pause()
    }
  }, [active])

  return (
    <div className='w-full rounded-2xl  flex keen-slider__slide'>
      <video className='w-full' poster={posterImage.asset.url} ref={videoRef} src={videoUrl} loop playsInline />
    </div>
  )
}

export const ProductInstagram = ({ data, main }: ProductInstagramProps) => {
  const { title, images, pairingVideo } = data
  const [slide, setSlide] = useState(1)
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    slidesPerView: 1.3,
    spacing: 10,
    centered: true,

    slideChanged(s) {
      setSlide(s.details().relativeSlide + 1)
    },
  })

  return (
    <div className='grid relative 800:mb-10 800:p-6 grid-cols-1 max-w-1200 800:gap-10 1000:grid-cols-8 1200:grid-cols-10'>
      <div className='1000:col-span-3 1000:pl-4 relative 1200:col-span-3'>
        {pairingVideo && (<PopupInfinite cerealImage={main.cerealImage} video={pairingVideo}  />)}
      </div>
      <div className='border-2 box-shadow-sm border-solid mt-8 800:mt-12 1000:mr-12 1000:col-span-5 1000:col-start-4 1200:col-start-5 1200:col-span-7'>
        <div className='bg-black p-2 mono uppercase text-white mono'>
          <span>{title}</span>
        </div>
        <div className='relative pt-2 pb-2'>
          <div className='w-full keen-slider overflow-hidden' ref={sliderRef}>
            {images.map((item, i) => {
              if (item.asset) {
                return (
                  <div className='w-full rounded-2xl  flex keen-slider__slide' key={i}>
                    <Image imageId={item.asset._id} className='w-full' alt={'slideshow images'} />
                  </div>
                )
              }
              if (item.videoUrl && item.posterImage) {
                return (
                  <VideoSlide {...item} key={i} active={slide === i + 1} />
                )
              }
            })}
          </div>
          <div className='absolute w-full z-10 bottom-0 p-4 left text-center 800:text-right '>
            <div className='w-32 mx-auto 800:ml-1 inline-block'>
              <div className='flex items-center justify-between rounded-full'>
                <button onClick={() => slider.next()} className='bg-yellow h-14 w-14 border-2 border-solid border-black rounded-full flex items-center justify-center'>
                  <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                    <path d="M4.83008 7.35547L2.18725 4.7094L4.83008 2.06333" stroke="#24211E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
                <button onClick={() => slider.prev()} className='bg-yellow h-14 w-14 border-2 border-solid border-black rounded-full flex items-center justify-center'>
                  <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                    <path d="M4.11035 2.06445L6.75318 4.71052L4.11035 7.35659" stroke="#24211E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
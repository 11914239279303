import React from "react"

import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"

export interface WarningProps {
  data: {
    requirements: any[]
    subTitle?: string
    title: string
  }
}

export const Warning = ({ data }: WarningProps) => {
  const { title, requirements, subTitle } = data
  return (
    <div className="container--xl outer mxa py2 al px1 x">
      <div className='ac'>
        <h2 className='offlimits mb0 h1 caps'>{title}</h2>
        <p className='caps s24 founders mt0'>{subTitle}</p>
      </div>
      <div className='f fw outer p1 jcb ais warning__row rel'>
        {requirements.map((requirement, i) => (
          <div key={i} className='f x warning__requirement rel z1 jcs aic my1'>
            <div className='warning__circle f jcc aic'><span className='h3 rel offlimits'>0{i + 1}</span></div>
            <p className='ml1 ss20 sm24 pl1'>{requirement}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
import React, { useEffect, useState } from "react";
import cx from "classnames";
const JUNIP_KEY = "Dn9GDPiceXpEm9aMMC6oxNZG";

import StarRatings from "react-star-ratings";
import spacetime from "spacetime";
import { usePage } from "src/context/siteContext";
import { ArrowTick } from "src/components/svgs";

export const ReviewsBlock = ({ includeReviews, shopify }) => {
  console.log("include", shopify);
  const [currentPage, setCurrentPage] = useState(0);
  const [stateReviews, setReviews] = useState([]);
  const [productId, setProductId] = useState("");
  const [overview, setOverview] = useState({});
  const [junipPageNext, setJunipPageNext] = useState(null);
  const [junipPagePrevious, setJunipPagePrevious] = useState(null);

  const { page } = usePage();

  const data = shopify?.productId
    ? {
        filter: {
          product_remote_id: shopify?.productId
        }
      }
    : {};

  console.log(data);

  useEffect(() => {
    // setTimeout(() => {
    //   setOverview(window.reviews)
    //   setReviews(window.reviewsArray)
    //   setJunipPageNext(window.reviewPagination.after)
    //   setProductId(window.product.productId)
    // }, 600)
    fetch(
      `https://api.juniphq.com/v1/product_reviews?include=customer&page[size]=6`,
      {
        data: JSON.stringify(data),
        headers: {
          "Junip-Store-Key": JUNIP_KEY,
          "Content-Type": "application/json"
        },
        type: "GET"
      }
    )
      .then(res => res.json())
      .then(json => {
        const { meta, product_reviews } = json;
        setReviews(product_reviews);
        setJunipPageNext(meta.page.after);
        console.log("hey json", json);
      });
  }, []);

  const handlePageClick = (page, direction, index) => {
    fetch(
      `https://api.juniphq.com/v1/product_reviews?include=customer&page[size]=6&page[${direction}]=${page}`,
      {
        method: "GET",
        data: JSON.stringify(data),
        headers: {
          "Junip-Store-Key": JUNIP_KEY,
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.json())
      .then(json => {
        const { meta, product_reviews } = json;
        setReviews(product_reviews);
        setJunipPageNext(meta.page.after);
        setJunipPagePrevious(meta.page.before);
        setCurrentPage(currentPage + index);
      });
  };

  const previousPage = (
    <button
      disabled={!junipPagePrevious}
      onClick={() => handlePageClick(junipPagePrevious, "before", -1)}
      className={cx(
        "border border-black border-solid w-16 h-16 items-center m-2 bg-orange rounded-full flex justify-center",
        {
          "opacity-50": !junipPagePrevious
        }
      )}
    >
      <div>
        <ArrowTick />
      </div>
    </button>
  );

  const nextPage = (
    <button
      disabled={!junipPageNext}
      onClick={() => handlePageClick(junipPageNext, "after", 1)}
      className="border border-black border-solid w-16 h-16 items-center m-2 bg-orange rounded-full flex justify-center"
    >
      <div className="transform rotate-180">
        <ArrowTick />
      </div>
    </button>
  );

  return stateReviews.length > 0 ? (
    <div className="p-8  800:p-12 bcw" id="reviews">
      <div className="df jcs aic border-b border-dashed border-black bb--black mb2 pb2">
        <h3 className="text-40 800:text-60 800:mt-10 offlimits caps">
          {" "}
          Reviews
        </h3>
      </div>
      {stateReviews.map(review => (
        <div
          key={review.id}
          className="border-b pt-4 pb-4 800:pt-8 800:pb-6 border-dashed border-black"
        >
          <div className="">
            <p className="flex justify-between">
              <div className="text-14 800:flex">
                <span className="uppercase mono">
                  {review.customer.first_name} {review.customer.last_name}
                </span>
                {review.verified_buyer && (
                  <span className="mono block 800:ml-6 opacity-50">
                    Verified Buyer
                  </span>
                )}
              </div>
              <span className="mono">
                {spacetime(review.created_at).format(
                  "{month-pad}/{day-pad}/{year}"
                )}
              </span>
            </p>
          </div>
          <div className="mb-2">
            <StarRatings
              rating={review.rating}
              starDimension="14px"
              starRatedColor="#000"
              starSpacing="1px"
            />
          </div>
          <div className="col mt2 mt0--w800 c12 c6--w800">
            <div className="gara line-break indent-lead rich-text fs18">
              <span className="text-24 800:text-30 caps">{review.title}</span>
              <p className="text-18 800:text-20">{review.body}</p>
            </div>
          </div>
        </div>
      ))}
      <div className="w-full mt-8">
        <div className="flex w-full justify-center">
          {previousPage}
          {/* currentPage + 1 */}
          {nextPage}
        </div>
      </div>
    </div>
  ) : (
    <span />
  );
};

import React from 'react'
import cx from 'classnames'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'
import { Image } from 'src/components/image'

export interface SplitModuleProps {
  data: {
    text: any[]
    backgroundColor: string
    imageAlignment?: string
    image: {
      asset: {
        id: string
      }
    }
  }
}

export const SplitModule = ({ data }: SplitModuleProps) => {
  const { text, backgroundColor, image, imageAlignment } = data
  return (
    <div className={cx('x split al rel bb z1 x', backgroundColor, {
      'cw': backgroundColor === 'bcb'
    })}>
      <div className={cx('x split__flex f fw jcb aic', imageAlignment)}>
        <Image imageId={image.asset.id} alt='cereal' className='x split__image' />
        <div className='ac p1 py1 outer m1 x split__text'>
          <div className='container--s mxa'>
            {text && (<BlockContent blocks={text} serializers={Serializer} />)}
          </div>
        </div>
      </div>
    </div>
  )
}
import React, { useState, useEffect } from "react";
import BlockContent from "@sanity/block-content-to-react";
import Draggable from "react-draggable";
import cx from "classnames";

import { Close } from "src/components/svgs";

import { Serializer } from "src/utils/serializer";
import { Image } from "src/components/image";
import { ProductForm } from "./form";
import ReactDOM from "react-dom";

export const Portal = ({ children }) => {
  const portalRoot = document.createElement("div");
  portalRoot.className =
    "fixed top-0 left-0 w-full h-full pointer-events-none z-50";

  useEffect(() => {
    document.body.appendChild(portalRoot);

    return () => {
      document.body.removeChild(portalRoot);
    };
  }, [children]);

  return ReactDOM.createPortal(children, portalRoot);
};

export const PopupFact = ({ text, title, index }) => {
  const [isActive, setIsActive] = useState(false);

  const isBrowser = typeof window !== "undefined";

  return (
    <React.Fragment>
      {isBrowser && (
        <Portal>
          <Draggable handle={`.js-header-${index}`}>
            <div
              className={cx(
                "how__popup fix top-30 800:left-1/2 z8 max-w-300 ba box-shadow-sm x",
                {
                  "float-r": index === 1,
                  "active pointer-events-auto": isActive,
                  "opacity-0": !isActive
                }
              )}
            >
              <button
                type="reset"
                className="bcy p05 bab draggable__close abs z2 cb"
                onClick={() => setIsActive(false)}
              >
                <Close className="block" />
              </button>
              <div
                className={`how__popup-header  p1 bcblue text-black f jcb aic js-header-${index}`}
              >
                <span className="s24 text-black">{title}</span>
              </div>
              <div className="popup-items al p1 bcw">
                {text && (
                  <BlockContent
                    projectId="0z8flqaa"
                    dataset="production"
                    blocks={text}
                    serializers={Serializer}
                  />
                )}
              </div>
            </div>
          </Draggable>
        </Portal>
      )}

      <div className="how__links rel ">
        <button
          onClick={() => setIsActive(true)}
          className="border-b rounded-none hover:border-solid border-dashed border-current text-24 m0 p0 font-bold"
        >
          {title}
        </button>
        {index === 0 && (
          <span className="text-20 mr-1 ml-1 duration-300 ease-in-out transition-colors">
            &amp;
          </span>
        )}
      </div>
    </React.Fragment>
  );
};

export const ProductPurchase = ({
  product,
  facts,
  main: {
    cleanTitle,
    badge,
    ticketValue,
    productType,
    colorAssociation,
    video,
    subTitle,
    productDescription,
    slug,
    singleImage,
    cerealImage
  }
}: {
  main: {
    cleanTitle?: string;
    subTitle?: string;
    slug: {};
    productDescription?: [];
    cerealImage: {};
    singleImage: {};
    ticketValue: number;
  };
  product: {
    defaultPrice: string;
    productId: number;
  };
  facts: any[];
}) => {
  return (
    <div className="relative overflow-hidden pl-5 pr-5 pt-8 pb-8 800:p-8 1000:pt-24">
      {cerealImage && (
        <Image
          className="hidden 800:block w-1/2 absolute -top-20 -right-20 c-r-s"
          imageId={cerealImage.asset._id}
          alt={cleanTitle}
        />
      )}
      <h1 className="text-40 800:text-60 text-center 800:text-left offlimits uppercase mb-4">
        <span className="text-white mb-1 inline-block">{cleanTitle}</span>
        <br />
        {subTitle}
      </h1>
      <div className="800:w-10/12">
        {productDescription && (
          <BlockContent blocks={productDescription} serializers={Serializer} />
        )}
        <div className="flex mt-2 800:mt-4">
          {facts &&
            facts.map((fact, i) => (
              <PopupFact {...fact} key={fact._key} index={i} />
            ))}
        </div>
      </div>
      <ProductForm
        {...product}
        type={productType}
        color={colorAssociation}
        badge={badge}
        title={cleanTitle}
        tickets={ticketValue}
        cerealImage={cerealImage}
        image={singleImage}
        sub={subTitle}
      />
    </div>
  );
};

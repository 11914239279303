import React, { useState, useEffect } from 'react'

import cx from 'classnames' 

import { shopify as storefront, globalID } from "src/shopify/client";
import { productQuery } from "src/shopify/queries";

import Draggable from 'react-draggable'

import BlockContent from "@sanity/block-content-to-react"

import {
  Close
} from 'src/components/svgs'

import { Serializer } from "src/utils/serializer"
import { Image } from 'src/components/image'

import { useAddItemToCart, useStore } from 'src/context/siteContext'

import {
  trackAddToCart
} from "src/components/analytics"

export interface VarietyPackProps {
  data: {
    title?: string
    subDescription?: [],
    product: {}
    products: Array<{
      content: {
        main: any
      }
    }>
  }
}

export const VarietyPack = ({ data }: VarietyPackProps) => {
  const addItemToCart = useAddItemToCart()
  const { checkout } = useStore()
  const [purchaseType, setPurchaseType] = useState('monthly')
  const [subPopup, setSubPopup] = useState(false)
  const [price, setPrice] = useState(0)
  const [variant, setVariant] = useState(null)
  const [quantity, setQuantity] = useState(1)
  const [available, setAvailable] = useState(false)
  const [activeVariantId, setActiveVariantId] = useState(null as string | null)
  const [check, setCheck] = useState(true)

  const {
    title,
    products,
    product,
    subDescription
  } = data
  
  const {
    main: {
      ticketValue
    }
  } = product.content

  useEffect(() => {
    if (check) { 
      storefront
        .fetch(productQuery, {
          id: globalID("Product", product.content.shopify.productId)
        })
        .then(data => {
          const product = data.product; 
          const variants = product?.variants.edges; 
          const defaultVariant = variants?.[0].node; 

          if (product && defaultVariant) { 
            setActiveVariantId(defaultVariant.id as string);
            setAvailable(defaultVariant.availableForSale);
            setPrice(defaultVariant.price.amount);
            setVariant(defaultVariant); 
            setCheck(false);
          }
        });
    }
  }, [check]);
 

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (available) {
      const customAttributes = [
        {key: '_subTitle', value: purchaseType === 'onetime' ? 'Variety Pack' : "Subscription" },
        {key: '_image', value: product.content.main.mainImage.asset.url },
        {key: '_ticketAdd', value: `${purchaseType === 'onetime' ? ticketValue : ticketValue * 2}` },
      ]
      if (purchaseType === 'monthly') {
        customAttributes.push({key: '_isSubscription', value: JSON.stringify(product.content.shopify.subscription, null, 4)})
      }
      console.log(product.content.shopify)
      addItemToCart(activeVariantId, quantity, customAttributes).then(() => {
        trackAddToCart(
          {
            name: title,
            id: activeVariantId || "error",
            price: parseFloat(price),
            variant: variant?.title || "error",
          },
          quantity,
          checkout
        )
      })
    }
  }

  return (
    <div className='cb x variety rel'>
      <div className='container--l outer mxa py2 al p1 x rel'>
        <div className='variety__content rel z3 p1 ac'>
          <div className='container--s mxa variety__header ac rel z1'>
            <h4 className='caps mb0 pb0 s100 offlimits'>{title}</h4>
          </div>
          <div className='container--s variety__content-text ss20 mxa mt1'>
            {subDescription && (<BlockContent blocks={subDescription} serializers={Serializer} />)}
          </div>
          <div className='variety__form box-shadow-sm ba bcw'>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className='f jcs aic variety__tickets'>
              <p className='bold m0 pb05 ml05'>${parseFloat(price)} (2 Boxes) - {purchaseType === 'onetime' ? ticketValue : ticketValue * 2} Tickets</p><img src='/mini-ticket.png' className='ml05 product__form-tickets' alt='ticket image' />
            </div>
              <Draggable handle={`.js-header-1`}>
                <div className={cx('how__popup fix z8 ba float-l box-shadow x', {
                  'active': subPopup
                })}>
                   <button type='reset' className='bcy p05 bab abs draggable__close z2 cb' onClick={() => setSubPopup(false)}>
                      <Close className='block' />
                    </button>
                  <div className={`how__popup-header  p1 bcblue f jcb aic js-header-1`}>
                    <span className='s24'>Members Only</span>
                   
                  </div>
                  <div className='popup-items al p1 bcw'>
                    <p>What comes in a membership?</p>
                    <ul>
                      <li className='s20'>Cereal every month so you never run out. </li>
                      <li className='s20'>Double tickets for every purchase (meaning more, better, cooler toys)</li>
                      <li className='s20'>First access to limited toy collaborations. </li>
                    </ul>
                  </div>
                </div>
              </Draggable>
            {available && !check ? (
              <React.Fragment>
                <div>
                  <label className='s20 product__form-purchase f jcs aic'>
                    <input type='radio' onChange={e => setPurchaseType(e.target.value)} name='purchaseType' value='monthly' checked={purchaseType === 'monthly'} />
                    <span>Get Cereal Every Month</span> <button type='button' onClick={() => setSubPopup(true)} className='product__form-help ml05 bctran f jcc aic'><span>?</span></button>
                  </label>
                </div>
                <div className='mb1'>
                  <label className='s20 product__form-purchase f jcs aic'>
                    <input type='radio' onChange={e => setPurchaseType(e.target.value)} name='purchaseType' value='onetime' checked={purchaseType === 'onetime'} />
                    <span>One-time Purchase</span>
                  </label>
                </div>
                <div className='s24  product__form'>
                  <div className='product__form-qty bcw cb bb f jcb aic'>
                    <div className='f jcc aic product__form-qty-wrapper mxa'>
                      <button type='button' className='block rel mr05 qty__control b-unset no-style s24 founders cursor p05 aic' onClick={() => quantity === 1 ? null : setQuantity(quantity - 1)}>
                        <img src='/qty_minus.png' alt='minus' />
                      </button>
                      <input type='number' value={quantity} onChange={e => setQuantity(parseInt(e.currentTarget.value, 10))} name='quantity' min='1' className='cb founders card-qty bn ac' />
                      <button type='button' className='qty__control b-unset no-style s1 block  founders s24 cursor rel p05 jcc aic' onClick={() => setQuantity(quantity + 1)}>
                      <img src='/qty_plus.png' alt='plus' />
                      </button>
                    </div>
                  </div>
                  <div className='product__form-button'>
                    <button type='submit' className='p1 s1 m0 button--black button--border s20 button'><span>Add to Cart</span></button>
                  </div>
                </div>
              </React.Fragment>
            ): (
              <div>
                {available ? (
                  <span>Checking Stock</span>
                ): (
                  <span>Unavailable</span>
                )}
              </div>
            )}
          </form>
          </div>

          {/* <h5 className='s14 caps s-desktop bold mt1 pt05 ls1'>free shipping</h5> */}
        </div>
        {products.map((product, i) => (
          <div className='variety__product abs' key={i}>
            <Image imageId={product.content.main.singleImage.asset._id} src={product.content.main.singleImage.asset.url} alt={title} />
          </div>
        ))}
      </div>
    </div>
  )
}
import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'

import { Image } from 'src/components/image'

export interface ProductCTAProps {
  data: {
    title: string
    subDescription: string
    characterImage: {
      asset: {
        id: string
      }
    }
    productReference: {
      content: {
        main: {
          colorAssociation: string
          title: string
          slug: {
            current: string
          }
          cerealImage: {
            asset: {
              id: string
            }
          }
        }
      }
    }
  }
}

export const ProductCTA = ({ data }: ProductCTAProps) => {
  const {
    productReference: {
      content: {
        main
      }
    },
    characterImage,
    title,
    subDescription
  } = data
  const splitWord = (word: string) => {
    const out = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < word.length; i++) {
      out.push(`<span class="wrapper">${word[i]}</span>`);
    }
    return out.join('')
  }
  return (
    <div className={cx('cta rel', main.colorAssociation)}>
      <div className="container--xl outer mxa  al px1 x">
        <div className='container--xl f fw jcb aic outer mxa shoppable__wrapper'>
          <Image imageId={main.cerealImage.asset.id} className='x cta__cereal-1 c-s abs' alt='Floating Cereal' />
          <Image imageId={main.cerealImage.asset.id} className='x cta__cereal-2 c-r-s abs' alt='Floating Cereal' />
          <Image imageId={main.cerealImage.asset.id} className='x cta__cereal-3 c-s abs' alt='Floating Cereal' />
          <Image imageId={main.cerealImage.asset.id} className='x cta__cereal-4 c-r-s abs' alt='Floating Cereal' />
          <div className='container--s shoppable__text mxa cta ac rel z1 p1'>
            <h2 className='offlimits h1 mb0 pb0 caps'>{title}</h2>
            <p className='h4 mt05 mb1 '>{subDescription}</p>
            <Link className='button box-shadow-sm ac button--black block s20 p1 button--md button--border' to={`/products/${main.slug.current}`}>
              <span className=''>Shop {main.title}</span>
            </Link>
          </div>
          <div className='shoppable__character rel x cta'>
          <h3 className={cx('shadow rel abs cw offlimits z1 h1-lg h1 bottom shoppable__character-text', main.slug.current, {
              'break-all lowercase': main.slug.current === 'dash',
              'caps': main.slug.current === 'zombie'
            })} dangerouslySetInnerHTML={{ __html: splitWord(main.title)}} />
            <Image className='x' imageId={characterImage.asset.id} alt={main.title} />
          </div>
        </div>
      </div>
    </div>
  )
}
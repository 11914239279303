import React, { useEffect, useState } from "react";
import EmblaCarouselReact from "embla-carousel-react";
import cx from "classnames";
import { Link } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";

import { Marqy } from "marqy";

import { Glitter } from "src/components/svgs";

import { ArrowTick } from "src/components/svgs";
import { Serializer } from "src/utils/serializer";

import { Image } from "src/components/image";
import { AddButton } from "../product/addButtonCTA";
import { PopupFact } from "../product/purchase";

export interface HeroSlideshowProps {
  data: {
    backgroundColor: string;
    bonusProductBadge: {};
    marquee: [];
    images: Array<{
      _key: string;
      asset: {
        _id: string;
      };
    }>;
  };
}

const AddToCart = ({ shopify, main }) => {
  const { subTitle, singleImage, colorAssociation } = main;
  return (
    <>
      <AddButton
        buttonClass="button box-shadow-sm ac button--black text-white s32 offlimits uppercase button--md mt-8 block pl-8 pr-8  w-auto button--border"
        {...shopify}
        color={colorAssociation}
        sub={subTitle}
        image={singleImage.asset._id}
      />
    </>
  );
};

export const HeroSlideshow = ({ data }: HeroSlideshowProps) => {
  const {
    backgroundColor,
    marquee,
    bonusProductVideo,
    bonusProductBadge,
    bonusProductCTA,
    bonusProductLink,
    text,
    video
  } = data;

  const product = data?.product?.content; 

  const images = data?.images || [];

  const [embla, setEmblaInsta] = useState(null);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        setIndex(embla.selectedScrollSnap());
      });
    }
  }, [embla]);

  const ShowMarquee = marquee && (
    <Marqy className="text-white flex font-14 mono uppercase bg-black">
      {marquee.map((marqtext, i) => (
        <span className="inline-block ml-2 mr-2" key={i}>
          &nbsp;{marqtext}&nbsp;
        </span>
      ))}
    </Marqy>
  );

  const displayText = text && (
    <div className="text-center 800:text-left p-large-serializer">
      
      <BlockContent blocks={text} serializers={Serializer} />
      {product && <AddToCart {...product} />}
    </div>
  );

  const featuredProduct = bonusProductLink && (
    <div>
      <div className="flex items-end mt-14 800:mt-0  justify-end">
        <div className="relative">
          <Glitter className="text-blue absolute z-10 top-1/2 -right-4 w-16" />
          <Glitter className="text-green absolute z-10 -left-4 top-20 w-24" />
          {bonusProductBadge && (
            <Image
              imageId={bonusProductBadge.asset._id}
              className="absolute z-10 w-40 h-40 -top-14 right-2"
              alt={"badge text"}
            />
          )}
          <div className={cx("relative w-full 800:w-300 rounded-3xl border-2 border-black overflow-hidden", backgroundColor)} style={{
            aspectRatio: '1 / 1'
          }}>
            <div className="absolute z-10 left bottom w-full p-4">
              <Link
                to={`/products/${bonusProductLink.content.main.slug.current}`}
                className=" bg-white hover:bg-pink rounded-3xl text-center p-2 mono w-full block uppercase text-14"
              >
                {bonusProductCTA}
              </Link>
            </div>
            <video
              src={bonusProductVideo}
              playsInline
              autoPlay
              loop
              muted
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );

  const showDots = images && (
    <div className="flex justify-center items-center 800:hidden bg-black w-full pb-2">
      {images.map((img, i) => (
        <div
          className={cx("w-2 h-2 border border-white rounded-full mr-2 ml-2", {
            "bg-white": index === i
          })}
          key={i}
        />
      ))}
    </div>
  );

  return (
    <div
      className={cx("w-full relative hero__slideshow", backgroundColor, {
        cw: backgroundColor === "bcb"
      })}
    >
      <div className="image__slideshow-wrapper rel">
        {images?.length ? (
          <EmblaCarouselReact
            emblaRef={setEmblaInsta}
            options={{ loop: true, startIndex: 0, align: "center" }}
          >
            <div className="x f w-full embla__container">
              {images?.map(({ asset }, i) => (
                <div
                  className="image__slideshow-slide  embla__slide x ac"
                  key={i}
                >
                  <Image imageId={asset._id} className="x" />
                </div>
              ))}
            </div>
          </EmblaCarouselReact>
        ) : (
          <></>
        )}

        {video && (
          <div className="w-full relative hero-video">
            <video
              src={video}
              playsInline
              autoPlay
              loop
              muted
              className="w-full h-full object-cover"
            />
          </div>
        )}
        <div className="absolute hidden 800:grid grid-cols-8 bottom left w-full h-full  items-end justify-between">
          <div className="p-8 max-w-900 w-full col-span-5">
            <div className="w-36 mx-auto mb-8 inline-block">
              <div className="hidden w-items-center justify-between rounded-full bg-black">
                <button
                  onClick={() => embla.scrollPrev()}
                  className="bg-yellow hover:bg-opacity-75 h-10 w-10 border-2 border-solid border-black rounded-full flex items-center justify-center"
                >
                  <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                    <title>Previous item</title>
                    <path
                      d="M4.83008 7.35547L2.18725 4.7094L4.83008 2.06333"
                      stroke="#24211E"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <span className="text-20 relative top-1 leading-tight text-white">
                  {index + 1}/{images.length}
                </span>
                <button
                  onClick={() => embla.scrollNext()}
                  className="bg-yellow hover:bg-opacity-75 h-10 w-10 border-2 border-solid border-black rounded-full flex items-center justify-center"
                >
                  <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                    <title>Next item</title>
                    <path
                      d="M4.11035 2.06445L6.75318 4.71052L4.11035 7.35659"
                      stroke="#24211E"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {displayText}
          </div>
          <div className="hidden 800:block pb-8 pr-8 col-span-3">
            <div className="flex items-end justify-end">
              <div className="max-w-300">{featuredProduct}</div>
            </div>
          </div>
        </div>
      </div>
      {showDots}
      <div className="800:hidden p-8 pt-10">
        {displayText}
        {featuredProduct}
      </div>
      {ShowMarquee}
    </div>
  );
};

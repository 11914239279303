import React from 'react'
import Draggable from 'react-draggable'

import {
  Close
} from 'src/components/svgs'

import { MarioStar } from 'src/components/star'

export const PopupSpinner = () => {
  return (
    <Draggable
      defaultPosition={{x: 10, y: 100}}>
      <div className='popup__spinner rel z3 bcy box-shadow ba'>
        {/* <button type='reset' className='bcy close abs m1 top right p05 bab z2 cb'>
          <Close className='block' />
        </button> */}
        <div className='p1'>
          <MarioStar className='popup__spinner-star' starName='spinnerStar' />
          <img className='cart__combo-image block p1 c-s x' src='/cart_combo.png' alt='Characters spinning' />
        </div>
      </div>
    </Draggable>
  )
}
import React, { useState } from "react"
import cx from 'classnames'

import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"

export interface TextWindowProps {
  data: {
    text: any[]
    title: string
    alignment: string
  }
}

export const TextWindow = ({ data }: TextWindowProps) => {
  const { title, text, alignment } = data

  return (
    <div className='bg-black overflow-hidden p-8 800:pt-20 800:pb-20 relative'>
      <div className='grid relative grid-cols-1 800:grid-cols-6 800:gap-20'>
        <div className={cx('flex flex-col-reverse 800:flex-col 800:col-span-4 justify-between', {
          '800:col-start-2': alignment === 'center',
          '800:col-start-3': alignment === 'right'
        })}>
          <div className='box-shadow-sm border-2 border-solid 800:mt-10'>
            <div className='bg-blue border-b-2 border-solid p-2'>
              <span className='text-black mono uppercase'>{title}</span>
            </div>
            <div className='bg-white text-black p-4 800:p-10'>
              <BlockContent blocks={text} serializers={Serializer} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
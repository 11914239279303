import React from 'react'
import Draggable from 'react-draggable'

import {
  Close
} from 'src/components/svgs'


const infinite = {
  'dash': {
    text: 'PICK-ME-UP'
  },
  'zombie': {
    text: 'CHOOSE TO SNOOZE'
  }
}

export const PopupMarquee = ({ character }: { character: string }) => {
  return (
    <Draggable>
      <div className='popup__marque abs z3 bcb box-shadow ba'>
        {/* <button type='reset' className='bcy close abs m1 top right p05 bab z2 cb'>
          <Close className='block' />
        </button> */}
        <div className='marquee py1'>
          <div className='marquee__inner'>
            <span className='h1 offlimits cw'>{infinite[character].text}</span>
            <span className='h1 offlimits cw'>{infinite[character].text}</span>
          </div>
        </div>
      </div>
    </Draggable>
  )
}
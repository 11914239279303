import React, { useState } from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'

export interface FaqModuleProps {
  data: {
    title?: string
    faqs: Array<{
      _key: string
      title: string
      answer: any[]
    }>
  }
}


export const FaqModule = ({ data }: FaqModuleProps) => {
  const [faqOpen, setFaqOpen] = useState(null)
  const {
    title,
    faqs
  } = data 
  return (
    <div className='f fw jcb outer faqs__block bg-gray pl-5 pr-5 py1 cg'>
      <div className='container--l ma p0 x faqs__block-wrapper jcb'>
        <h4 className='m0 caps offlimits s48 py1 caps'>{title}</h4>
        {faqs && faqs.length > 0 && faqs.map((faq, i) => (
          <div className={cx('x al py1 faqs__single', {
            'is-open': faqOpen === i
          })} key={faq._key}>
            <div onClick={() => faqOpen === i ? setFaqOpen(null) : setFaqOpen(i)} className='x f jcb p0 m0 modern cg bctran aic'>
              <h4 className='s20 al m0 p0'>{faq.title}</h4>
              <button 
                className={cx('faqs__single-button bcw p-2 ba')}
                onClick={() => faqOpen === i ? setFaqOpen(null) : setFaqOpen(i)}>
                <img src='/qty_plus.png' className='w-3  h-auto' alt='plus' />
              </button>
            </div>
            <div className={cx('faqs__single-answer text-18', {
              'mt-2': faqOpen === i
            })}>
              <BlockContent blocks={faq.answer} />
            </div>
          </div>
        ))}
      </div>
    </div>
 )
}
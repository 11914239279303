import React, { useState, useEffect } from 'react'

import { GalleryCard } from 'src/components/product/galleryCard'

export interface GalleryGridProps {
  data: {
    title?: string
    subTitle?: string
    displayCount: number
    products: Array<{
      id: string
    }>
  }
}

export const GalleryGrid = ({ data }: GalleryGridProps) => {
  const [stateProducts, setStateProducts] = useState(Array)
  const {
    title,
    subTitle,
    displayCount,
    products
  } = data

  useEffect(() => {
    if (products.length > 0) {
      setStateProducts(products.slice(0, displayCount))
    }
  }, [0])
  return (
    <div className='bcw cb x gallery__module'>
      <div className='container--l outer mxa py2 al p1 x rel'>
        <div className='container--m mxa gallery__header ac rel z1'>
          <h4 className='caps mb0 pb0 s48 offlimits'>{title}</h4>
          <p className='mb0 pt0 ss20 sm24 mt05'>{subTitle}</p>
        </div>
        <div className='f jcb ais fw'>
          {stateProducts.map((product: any, i) => (
            <React.Fragment key={i}>
              <GalleryCard content={product.content} id={product.id} />
            </React.Fragment>
          ))}
        </div>
        {stateProducts.length < products.length && (
          <div className='ac'>
            <button onClick={() => setStateProducts(products)} className='button box-shadow-sm ac button--black block s20 p1 button--md button--border'><span>Show More</span></button>
          </div>
        )}
      </div>
    </div>
  )
}
import React from 'react'
import cx from 'classnames'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'
import { Image } from 'src/components/image'

export interface ImageTextProps {
  data: {
    text: any[]
    imageSize?: string
    backgroundColor: string
    imageAlignment?: string
    imageFrame?: boolean
    image: {
      asset: {
        id: string
      }
    }
  }
}

export const ImageText = ({ data }: ImageTextProps) => {
  const { text, backgroundColor, image, imageAlignment, imageSize = '', imageFrame = false } = data
  return (
    <div className={cx('x split it al rel z1 x', backgroundColor, {
      'cw': backgroundColor === 'bcb'
    })}>
      <div className='container--xl mxa p1 outer'>
        <div className={cx('x split__flex f fw jcb aic', imageAlignment)}>
          <div className='x'>
            <Image imageId={image.asset.id} alt='cereal' className={cx('', {
              'artframe': imageFrame,
              'larger': imageSize === 'large'
            })} />
          </div>
          <div className='ac p1 py1 outer m1 x split__text mini'>
            <div className='container--s alm s20 sm24 mxa'>
              {text && (<BlockContent blocks={text} serializers={Serializer} />)}
            </div>
          </div>
        </div>
      </div>.
    </div>
  )
}
import React, { useState, useEffect } from 'react'
import { Image } from 'src/components/image'

import { GalleryAdd } from 'src/components/product/galleryAdd'

export interface GalleryCardProps {
  content: {
    main: {
      mainImage: {}
      size?: string
      subTitle: string
      title: string
    }
    shopify: {
      defaultPrice: string
    }
  }
  id: string
}

export const GalleryCard = ({ content, id }: GalleryCardProps) => {
  const [addToCart, setAddToCart] = useState(false)
  const {
    mainImage,
    size,
    subTitle,
    title
  } = content.main
  const {
    defaultPrice
  } = content.shopify
  return (
    <div className='x c40 gallery__grid-single rel'>
      {addToCart && (
        <GalleryAdd handleHide={() => setAddToCart(false)} artContent={content.main} artProduct={content.shopify} />
      )}
      <Image onClick={() => setAddToCart(true)} className='block artframe x' imageId={mainImage.asset._id} alt={title} />
      <div className='mt1 f jcb ais'>
        <div>
          <h4 className='s24'>{title}</h4>
          <p className='caps m0 p0 s14 bold founders ls1'>{subTitle}<br />{size}</p>
        </div>
        <div>
          <span className='s14 founders bold'>${parseInt(defaultPrice, 0).toFixed(0)}</span>
        </div>
      </div>
      <div>
        <button onClick={() => setAddToCart(true)} className='underline-extra bctran  gallery__grid-button caps founders ls1 bold mt1 cb '>See More</button>
      </div>
    </div>
  )
}
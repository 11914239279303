import React, { useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import BlockContent from "@sanity/block-content-to-react"
import { shopify as storefront, globalID } from "src/shopify/client";
import { productQuery } from "src/shopify/queries";

import { Serializer } from "src/utils/serializer"

import {
  Close
} from 'src/components/svgs'

import { useAddItemToCart } from 'context/siteContext'

export const GalleryAdd = ({ artProduct, artContent, handleHide }) => {
  const addItemToCart = useAddItemToCart()

  const [quantity, setQuantity] = useState(1)
  const [available, setAvailable] = useState(false)
  const [activeVariantId, setActiveVariantId] = useState(null as string | null)
  const [check, setCheck] = useState(true)

  
  useEffect(() => {
    if (check) { 
      storefront
        .fetch(productQuery, {
          id: globalID("Product", artProduct.productId)
        })
        .then(data => {
          const product = data.product; 
          const variants = product?.variants.edges; 
          const defaultVariant = variants?.[0].node; 

          if (product && defaultVariant) { 
            setActiveVariantId(defaultVariant.id as string);
            setAvailable(defaultVariant.availableForSale); 
            setCheck(false);
          }
        });
    }
  }, [check]); 
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    console.log(artContent)
    if (available) {
      const customAttributes = [
        {key: '_subTitle', value: artContent.subTitle },
        {key: '_image', value: artContent.mainImage.asset.url },
        {key: '_ticketAdd', value: `${artContent.ticketValue}` },
        {key: '_isArt', value: `true` }
      ]
      addItemToCart(activeVariantId, quantity, customAttributes)
    }
  }
  return (
    <Draggable
      handle='.js-drag'
      defaultPosition={{x: 10, y: -10}}>
      <div className='popup__gallery abs al oh z3 bcw box-shadow ba'>

        <div className='js-drag'>

          <h4 className='x px1 pt1 pb0'>{artContent.title}</h4>
          {/* <span className='block x p1 m1 rel' /> */}
        </div>
        <button onClick={handleHide} aria-label={`close ${artContent.title} modal`} type='reset' className='bcy close abs draggable__close m1 top right p05 bab z2 cb'>
          <Close className='block' />
        </button>
        <div className=''>
          <div className='popup__gallery-inner p1'>
            <p className='mb0 ls1 p0 caps s16 mt1'>
              {artContent.subTitle}<br />
              {artContent.size}
            </p>
            <span className='my1 block ls1 s16'>${parseInt(artProduct.defaultPrice, 0).toFixed(0)}</span>
            {artContent.productDescription && (
              <React.Fragment>
                <h5 className='m0 p0 s16 caps ls1'>What you get</h5>
                <BlockContent blocks={artContent.productDescription} serializers={Serializer} />
              </React.Fragment>
            )}
          </div>
          <div>
          {available && !check ? (
            <form onSubmit={e => handleSubmit(e)} className='f x jcb aic'>
              <div className='product__form-button x'>
                <button type='submit' className='p1 x s1 button--black button--border s20 button'><span>Add to Cart</span></button>
              </div>
            </form>
          ) : (
            <span className='p1 ac s20 x block bt'>Currently Sold Out</span>
          )}
          </div>
        </div>
      </div>
    </Draggable>
  )
}

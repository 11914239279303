import React from 'react'
import cx from 'classnames'

export interface PressGridProps {
  data: {
    title?: string
    press: Array<{
      _id: string
      logo: {
        asset: {
          url: string
        }
      }
      quote: string
      colorAssociation: string
      linkOut: string
    }>
  }
}

export const PressGrid = ({ data }: PressGridProps) => {
  const {
    press
  } = data

  return (
    <div className='bg-gray'>
      <div className='container--xl mxa px1 py1 x press__grid outer f fw jcb ais'>
        {press.map(({
          quote,
          logo,
          linkOut,
          colorAssociation
        }, i) => (
          <div className='x c30 ba box-shadow-sm mb1 press__grid-single' key={i}>
            <div className={cx('p1 f jcc aic', colorAssociation)}>
              <div className='mxa ac press__grid-img'>
                <img src={logo.asset.url} className='inline-block x' alt={quote} />
              </div>
            </div>
            <div className='bcw'>
              <div className='container--m mxa px2 py1 bt'>
                <p>{quote}</p>
                {linkOut && (<a href={linkOut} target='_blank' className='s1 inline-block  mb1 underline-extra mt1'>Read More</a>)}
              </div>
            </div>
          </div>
        ))}
        <div className='c30 x' />
        <div className='c30 x' />
      </div>
    </div>
  )
}
import React from "react"

import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"

export interface StandardTextProps {
  data: {
    text: any[]
  }
}

export const StandardText = ({ data }: StandardTextProps) => {
  const { text } = data
  return (
    <div className='bcblue'>
      <div className="container--xl standard outer mxa py2 al p1 x">
        <div className='container--1000 mxa'>
          {text && (<BlockContent blocks={text} serializers={Serializer} />)}
        </div>
      </div>
    </div>
  )
}
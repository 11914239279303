import React, { useEffect, useState } from "react"
import cx from 'classnames'

import { PageLink } from 'src/components/link'
import { Image } from 'src/components/image'

import { useToggleShop } from 'src/context/siteContext'

import {
  SoundOn,
  SoundOff
} from 'src/components/svgs'

export interface MainHeroProps {
  data: {
    backgroundColor?: string
    title: string
    subTitle: string
    backgroundImage: {
      asset: {
        _id: string
      }
    }
    shopLinks: Array<{
      _id: string
      content: {
        main: {
          title: string
          slug: {
            current: string
          }
        }
      }
    }>
  }
}

const desktopVideo = `https://player.vimeo.com/external/516275645.hd.mp4?s=e3014c4d89fd9fdd06e19e529106817dafab111f&profile_id=174`
const mobileVideo = `https://player.vimeo.com/external/517216130.hd.mp4?s=1d5f878e599a0832fb7c8d4ee6d341a32972fe32&profile_id=174`

export const MainHero = ({ data }: MainHeroProps) => {
  const [soundOn, toggleSoundOn] = useState(false)
  const videoRef = React.createRef()
  const toggleShop = useToggleShop()
  const { backgroundColor, shopLinks, subTitle, title, backgroundImage: { asset } } = data

  const toggleSound = () => {
    const video = videoRef.current
    if (video) {
      video.muted = soundOn
      toggleSoundOn(!soundOn)
    }
  }

  useEffect(() => {
    const size = window.innerWidth
    const video = videoRef.current
    if (size > 700) {
      video.src = desktopVideo
    }
    window.addEventListener('resize', () => {
      const resize = window.innerWidth
      if (resize > 700) {
        video.src = desktopVideo
      } else {
        video.src = mobileVideo
      }
    })
  }, [])

  return (
    <div className={cx('p1 outer hero__main rel z2 f jcc aie ac x', backgroundColor)}>
      <video ref={videoRef} autoPlay muted playsInline loop className='abs object-fit x y top left'>
        <source src='https://player.vimeo.com/external/517216130.hd.mp4?s=1d5f878e599a0832fb7c8d4ee6d341a32972fe32&profile_id=174' />
      </video>
      <div className='pr z1 hero__main-content x'>
        <div className='abs bottom right pb1 hero__main-sound'>
          <div className=''>
            <button aria-label='turn on sound for video' className='hero__sound bcb cw color--white' onClick={() => toggleSound()}>
            {soundOn ? (
              <SoundOn />
            ) : (
              <SoundOff />
            )}
            </button>
          </div>
        </div>
        {/* <h1 className='offlimits rel z2 cw h1-display shadow caps' dangerouslySetInnerHTML={{ __html: title.replace(/[\r\n]+/g, '<br />') }} />
        <p className='h4 mt05 rel z2 pt0'>{subTitle}</p> */}
        <button onClick={() => toggleShop()} className="button  box-shadow-sm button--md x ac hero__links-desktop button--black block s20 p1 button--md button--border"><span>Shop Cereal</span></button>
        <div className='hero__links f jcb aic z3'>
          {shopLinks.map(({_id, content: { main }}) => (
            <PageLink key={_id} className={`hero__links-single s20 my05 c-${main.slug.current}`} to={`/products/${main.slug.current}`}>Shop {main.title}</PageLink>
          ))}
        </div>
      </div>
      {/* <Image imageId={asset._id} alt={title} className='abs x top c-s hero__main-image left y' /> */}
    </div>
  )
}
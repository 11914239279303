import React from "react"
import cx from 'classnames'

import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"
import { Image } from 'src/components/image'

export interface IngredientsBlockProps {
  data: {
    ingredients: any[]
    title: string
  }
}

export const IngredientsBlock = ({ data }: IngredientsBlockProps) => {
  const { title, ingredients } = data
  return (
    <div className="bg-black text-white pt-8 pb-8 pl-5 pr-5 800:pl-12 800:pr-12 800:py-20">
      <h3 className='text-center max-w-700 mb-10 offlimits uppercase text-40 leading-40 800:leading-tight 800:text-60 800:text-left'>{title}</h3>
      {ingredients && ingredients.map((single, i) => (
        <div key={single._key} className={cx('py-4 800:grid 800:grid-cols-8 800:gap-6 border-b border-dashed border-white 800:py-8', {
          'border-t': i === 0
        })}>
          <div className='grid grid-cols-6 800:col-span-2 800:block'>
            {single.image && (<Image imageId={single.image.asset._id} className='col-span-2 rounded-xl overflow-hidden col-start-5 w-full' alt={single.name} />)}
          </div>
          <div className='800:col-span-5 800:flex 800:justify-between 800:flex-col'>
            <span className='text-24  800:text-30'>{single.name}</span>
            <div className='800:pr-8'>
              {single.text && (<BlockContent blocks={single.text} serializers={Serializer} />)}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
import React from "react"
import {Marqy} from 'marqy'
import { Link } from 'gatsby'
import cx from 'classnames'

import { AddButton } from 'src/components/product/addButton'

import Scrollbar from 'react-scrollbars-custom'
import { Image } from 'src/components/image'

export interface ToyStoreBlockProps {
  data: {
    text: any[]
  }
}

export const ToyStoreBlock = ({ data }: ToyStoreBlockProps) => {
  const { marqueeTicker, featuredProduct, products } = data
  const marquee = marqueeTicker && (
    <Marqy className='mono pb-1 pt-1 bg-black text-gray text-14 uppercase' speed={0.2}>
      {marqueeTicker.map((marqtext, i) => (<span className='inline-block ml-2 mr-2' key={i}>&nbsp;{marqtext}&nbsp;</span>))}
    </Marqy>
  )
  return (
    <div className='800:p-12 pt-12 pb-12 w-full bg-gray'>
      <div className='w-full box-shadow-sm rounded-b-lg rounded-t-lg border-b-4'>
        <div className='rounded-t-lg overflow-hidden'>
          {marquee}
        </div>
        <div className='800:border-l-2 800:border-r-2 border-black bg-black'>
        <div className='bg-whiter overflow-hidden rounded-t-lg'>
          {featuredProduct && (
            <div className='grid border-b-2 grid-cols-1 800:grid-cols-9'>
              <div className='p-6 text-center 800:col-span-3'>
                <div className='flex justify-between flex-col h-full'>
                  <div className='pb-8'>
                    <h3 className='text-60 founders' dangerouslySetInnerHTML={{ __html: featuredProduct.content.main.cleanTitle }} />
                    <span className='text-20'>${featuredProduct.content.shopify.defaultPrice}</span>
                  </div>
                  <div>
                    <AddButton buttonText={'Add to Cart'} buttonClass='border-b text-20 border-dashed text-black' {...featuredProduct.content.shopify} color={featuredProduct.content.main.colorAssociation} sub={featuredProduct.content.main.subTitle} image={featuredProduct.content.main.singleImage.asset._id} />
                  </div>
                </div>
              </div>
              <div className='relative overflow-hidden 800:col-span-6 800:border-l-2 border-solid'>
                {featuredProduct.content.main.badge && (
                  <div className='absolute top 800:bottom-0 800:top-auto z-10 left m-8'>
                    <h5 className='mono text-14 rounded-sm mx-auto text-center bg-yellow inline-block text-black uppercase p-2 pl-4 pr-4'>{featuredProduct.content.main.badge}</h5>
                  </div>
                )}
                <div className='relative z-10 grid grid-cols-2 p-6 pt-20 800:pt-36 600:grid-cols-4'>
                  <div className='border-2 rounded-lg p-3 mt-30 bg-whiter border-black 600:col-start-4'>
                    {featuredProduct.content.main.singleImage && (
                      <Image
                        imageId={featuredProduct.content.main.singleImage.asset._id}
                        alt='cereal'
                        className='w-full' />
                    )}
                  </div>
                </div>
                {featuredProduct.content.clp && featuredProduct.content.clp.hoverImage && (
                  <Image
                    imageId={featuredProduct.content.clp.hoverImage.asset.id}
                    alt='cereal'
                    className='absolute abs inset-0 top-0 object-cover object-center left-0 w-full h-full' />
                )}
              </div>
            </div>
          )}
          <div>
            {products && (
              <Scrollbar style={{ width: '100%', height: 400 }} noScrollY={true}>
                <div className='whitespace-nowrap w-full'>
                  <div className='flex items-start'>
                    {products.map(product => (
                      <div key={product._id} className='w-1/4 min-w-340 inline-block p-4 800:p-8 border-r-2 border-solid' style={{ height: '420px'}}>
                        <Link to='/toy-store'>
                          <h5 className='text-20 founders font-light'>{product.content.main.title}</h5>
                          <span className='text-20 founders font-light'>${product.content.shopify.defaultPrice}</span>
                          <div className='relative w-10/12 mx-auto'>
                            {product.content.main.videoUrl && (
                              <div className='absolute top left w-full h-full'>
                                <video src={product.content.main.videoUrl} className='absolute w-full h-full top left z-10' autoPlay loop playsInline muted />
                              </div>
                            )}
                            {product.content.main.singleImage && (
                              <Image
                                imageId={product.content.main.singleImage.asset._id}
                                alt='cereal'
                                className='w-full' />
                            )}
                          </div>
                        </Link>
                        <div>
                          <AddButton buttonText={'Add to Cart'} buttonClass='border-b text-20 border-dashed text-black' {...product.content.shopify} color={product.content.main.colorAssociation} sub={product.content.main.subTitle} image={product.content.main.singleImage.asset._id} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Scrollbar>
            )}
          </div>
        </div>
        <div>
          <Link to='/toy-store' className='button ac button--black rounded-b-lg block s20 w-full'>Enter the Toy Store</Link>
        </div>
        </div>
      </div>
    </div>
  )
}
import React, { useState } from 'react'
import { decode } from 'shopify-gid'

import {
  Arrow
} from 'src/components/svgs'


export const Waitlist = ({ accountId, message, buttonText, variantId }: {
  accountId: string
  message: string
  buttonText: string
  variantId: string
}) => {
  const [success, setSuccess] = useState(false)
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const form = e.currentTarget
    const { email } = form.elements
    const productIdDecoded = decode(variantId).id
    fetch('/.netlify/functions/back-in-stock', {
      method: 'POST',
      body: JSON.stringify({
        accountId,
        email: email.value,
        variant: productIdDecoded,
        platform: 'shopify'
      })
    })
    .then(() => {
      setSuccess(true)
    })
    
  }
  return (
    <React.Fragment>
    {!success ? (
      <form onSubmit={e => handleSubmit(e)} className='w-11/12 border-b-2 mt-2 border-dashed mr-4 rel founder jcb aist'>
        <input name='email' className='x mono text-16 border-none pt-3 pb-3 founders' placeholder='Email' type='email' required={true} />
        <button type="submit" className="abs y cb top right f jcc aic">
          <Arrow className={'cb'} />     
        </button>
      </form>
    ): (
      <div>
        <p>{message}</p>
      </div>
    )}
  </React.Fragment>
  )
}
import React from 'react'

export interface CoreFeaturesProps {
  data: {
    subTitle: string
    features: any[]
    subBottom: string
    characterImage: {}
  }
  main: {
    cerealImage: {
      asset: {
        _id: string
      }
    }
  }
}

import { Image } from 'src/components/image'

export const CoreFeatures = ({ data, main }: CoreFeaturesProps) => {
  const {
    subTitle,
    subBottom,
    features,
    characterImage
  } = data
  const {
    cerealImage
  } = main
  return (
    <div className='bg-gray relative overflow-hidden border-b-2 pt-20 border-solid pb-80'>
      <div className='p-5 800:p-20'>
        {subTitle && (<h5 className='uppercase roboto font-medium mb-4'>{subTitle}</h5>)}

        <h4 className='uppercase text-48 leading-40 600:text-60 1200:max-w-800 1400:text-80 1400:leading-80 600:leading-60 offlimits'>{features}</h4>
        {subBottom && (<h5 className='uppercase roboto font-medium mb-4'>{subBottom}</h5>)}
      </div>
      <div className='w-6/12 absolute right-4 bottom-4 800:w-4/12 800:right-20'>
        <Image imageId={characterImage.asset.id} className='scale-110 relative bottom-10' alt='character illustration' />
      </div>
      <Image className=' w-1/2 absolute -left-20 600:-left-30 800:-bottom-40 800:-left-60 1000:max-w-500 c-r-s' imageId={cerealImage.asset._id} alt={subTitle} />
      <Image className=' w-1/4 absolute -top-10 -right-10 800:top-10 800:right-10 800:w-36 c-r-s' imageId={cerealImage.asset._id} alt={subTitle} />
    </div>
  )
}
import React from 'react'
import Draggable from 'react-draggable'

import { Image } from 'src/components/image'
import {
  Close
} from 'src/components/svgs'

export const PopupInfinite = ({ cerealImage, video }: {
  cerealImage: {
    asset: {
      url: string
    }
  }
}) => {

  const emptyArray = [...Array(8)]
  return (
    <Draggable
      defaultPosition={{x: 10, y: 100}}>
      <div className='popup__infinite w-full abs z3 right bcy box-shadow-sm border-2 border-black border-solid oh'>
        {video ? (
          <video src={video} autoPlay muted loop playsInline className='w-full h-full object-fit object-center' />
        ) : (
          <div>
            <div className='popup__infinite-dash abs x y top left f jcc aic'>
              {emptyArray.map((item, i) => (
                <img key={i} className='x abs' src={`${cerealImage.asset.url}?h=400`} alt='cereal image' />
              ))}
            </div>
            <div className='popup__infinite-dash two abs x y top left f jcc aic'>
              {emptyArray.map((item, i) => (
                <img key={i} className='x abs' src={`${cerealImage.asset.url}?h=400`} alt='cereal image' />
              ))}
            </div>
          </div>
        )}
      </div>
    </Draggable>
  )
}
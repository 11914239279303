import React from "react"
import {Marqy} from 'marqy'
import cx from 'classnames'

export interface MarqueeProps {
  data: {
    text: any[]
  }
}

export const Marquee = ({ data }: MarqueeProps) => {
  const { text, backgroundColor, textSize = 'small' } = data
  const marquee = text && (
    <Marqy className={cx(' pb-1 pt-1 uppercase', {
      'text-48 800:text-80 offlimits 800:leading-80 1000:leading-120 800:pt-4 1000:text-120': textSize === 'large',
      'text-48 800:text-76 offlimits 800:leading-80 800:pt-4 test': textSize === 'medium',
      'text-14 mono': textSize === 'small',
      'text-gray': backgroundColor === 'bcb'
    })} speed={0.2}>
      {text.map((marqtext, i) => (<span className='inline-block ml-2 mr-2' key={i}>{marqtext}</span>))}
    </Marqy>
  )
  return (
    <div className={backgroundColor}>
      {marquee}
    </div>
  )
}
import React, { useState } from "react"

import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'

import { Image } from 'src/components/image'

export interface ColumnSlideshowProps {
  data: {
    images: any[]
  }
}

export const ColumnSlideshow = ({ data }: ColumnSlideshowProps) => {
  const { images } = data
  const [slide, setSlide] = useState(1)
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    breakpoints: {
      '(min-width: 800px)': {
        slidesPerView: 2,
      }
    }
  })
  return (
    <div className='border-b-2 border-solid' style={{ width: 'calc(100% + 2px)'}}>
      <div className='w-full keen-slider overflow-hidden' ref={sliderRef}>
        {images.map(({
          asset
        }, i) => (
          <div className='w-full flex border-r-2 border-solid border-black keen-slider__slide' key={i}>
            <Image imageId={asset._id} className='w-full' alt={'slideshow images'} />
          </div>
        ))}
      </div>
    </div>
  )
}
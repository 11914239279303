import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import EmblaCarouselReact from 'embla-carousel-react'

import { Image } from 'src/components/image'

import {
  ArrowTick
} from 'src/components/svgs'

export interface ReviewsProps {
  data: {
    title: string
    reviews: []
  }
  main: {
    slug: {
      current: string
    }
  }
}

export const Reviews = ({ data, main: { slug: { current }} }: ReviewsProps) => {

  const [embla, setEmbla] = useState(null)
  const [index, setIndex] = useState(0)

  const { title, reviews } = data

  useEffect(() => {
    if (embla) {
      embla.on('select', () => {
        setIndex(embla.selectedScrollSnap())
      })
    }
  }, [embla])
  return (
    <div className={cx('reviews bcw p1 pmy', {
      'hide-controls': reviews.length === 1
    })}>
      <div className="container--l ac outer mxa py2 rel x">
        <h4 className='caps offlimits'>{title}</h4>
        <div className={cx('review-wrapper mxa')}>
          <EmblaCarouselReact
            emblaRef={setEmbla}
            options={{ loop: true }}>
              <div className='x f review-slideshow embla__container'>
                {reviews.map(({
                  _key,
                  review,
                  reviewHead,
                  reviewee
                }) => (
                  <div className='review__slide embla__slide x ac p1' key={_key}>
                    <div className='py1 x'>
                      <div className='container--m mxa'>
                        <h4>{review}</h4>
                        <p>{reviewee}</p>
                        {reviewHead && (<Image className='review__head' imageId={reviewHead.asset.id} alt={reviewee} />)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button className={cx('review__arrow abs m1 cb left', {
                'bcy': current === 'dash',
                'bcp': current === 'zombie'
              })} onClick={() => embla.scrollPrev()}><ArrowTick /></button>
              <button className={cx('review__arrow abs cb m1 right', {
                'bcy': current === 'dash',
                'bcp': current === 'zombie'
              })} onClick={() => embla.scrollNext()}><ArrowTick /></button>
            </EmblaCarouselReact>
          </div>
          <div className='review__dots f jcc aic'>
            {reviews.map((review, i) => (
              <div key={review._key} className={cx('review__dots-single bcb m05', {
                'active': index === i
              })} />
            ))}
          </div>
      </div>
    </div>
  )
}
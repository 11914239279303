import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'

import { Image } from 'src/components/image'
import { MarioStar } from 'src/components/star'

export interface DoubleCtaProps {
  data: {
    products: Array<{
      _key: string
      badgeText: string
      title: string
      characterImage: {}
      productReference: {
        content: {
          main: {
            colorAssociation: string
            slug: {
              current: string
            }
          }
        }
      }
    }>
  }
}


export const DoubleCta = ({ data }: DoubleCtaProps) => {
  const {
    products
  } = data
  return (
    <div className='f jcb fw aic x aist'>
      {products.map(product => {
        const {
          badgeText,
          title,
          subDescription,
          characterImage
        } = product
        const {
          content: {
            main
          }
        } = product.productReference
        return (
          <div key={product._key} className={cx('x chalf double__cta rel oh bt outer ac p15', main.colorAssociation, main.slug.current)}>
            <Image imageId={main.cerealImage.asset._ref} className='x double__cta-c-1 c-s abs' alt='Floating Cereal' />
            <Image imageId={main.cerealImage.asset._ref} className='x double__cta-c-2 c-s abs' alt='Floating Cereal' />
            <Image imageId={main.cerealImage.asset._ref} className='x double__cta-c-3 c-s abs' alt='Floating Cereal' />
            <div className='double__cta-wrapper rel z2'>
              <h5 className='caps founders  ls1'>{badgeText}</h5>
              <div className='double__cta-image rel x'>
                <MarioStar className='double__cta-star' starName={`ctaStar${main.slug.current}`} />
                <Image imageId={characterImage.asset.id} alt='cereal' className='x' />
              </div>
              <div className='mxa double__cta-text'>
                <h2 className='offlimits mt05 mb0 p0 caps'>{title}</h2>
                <p className='s24 mxa'>{subDescription}</p>
                <Link className='button box-shadow-sm ac button--black block s20 p1 button--md button--border' to={`/products/${main.slug.current}`}>
                  <span className=''>Shop {main.title}</span>
                </Link>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
import React, { useEffect, useState, useRef } from 'react'
import cx from 'classnames'

export interface TextSwitcherProps {
  data: {
    swapText: Array<{
      _key: string
      headerBottom: string
      headerSwap: string
      bottomTextColor: string
    }>
  }
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const TextSwitcher = ({ data }: TextSwitcherProps) => {
  const [isRunning, setIsRunning] = useState(true)
  const [active, setActive] = useState(0)
  const { swapText } = data
  
  useInterval(() => {
    (active === swapText.length - 1) ? setActive(0) : setActive(active + 1)
  }, isRunning ? 3000 : null)

  return (
    <div className='x ac bcblue bt bb'>
      <div className='container--xl outer text__switch my1 p1'>
        <div className='rel text__switch-sizer'>
          {swapText.map(({ _key, headerSwap }, i) => (
            <div key={_key} className=''>
              <h3 className={cx('s100 offlimits  ac p0 caps', {
                'active': active === i
              })}>{headerSwap}</h3>
            </div>
          ))}
        </div>
        <span className='inline-block text__switch-line' />
        <div className='rel text__switch-sizer'>
          {swapText.map(({ _key, headerBottom, bottomTextColor }, i) => (
            <div key={_key} className=''>
              <h3 className={cx('s100 offlimits p0  ac shadow caps', bottomTextColor, {
                'active': active === i
              })}>{headerBottom}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
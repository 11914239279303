import React from 'react'

export interface VideoProps {
  data: {
    videoUrl: string
  }
}

export const Video = ({ data }: VideoProps) => {
  const {
    videoUrl
  } = data
  return (
    <div className='border-b-2 border-solid product__video relative'>
      <video src={videoUrl} playsInline muted loop autoPlay className='w-full' />
    </div>
  )
}
import React from 'react'
import { Modules } from "src/components/modules"

export const RenderModules = (modules: [], main?: {} | undefined, shopify?: {} | undefined) => {
  if (modules) {
    return modules.map((module: {
      _key: string,
      _type: string
    }) => {
      return <Modules
        key={module._key}
        type={module._type}
        cereal={main}
        product={shopify}
        reactModule={module} />
    })
  }
}
import React, { useState, useEffect } from "react"; 
import { shopify as storefront, globalID } from "src/shopify/client";
import { productQuery } from "src/shopify/queries";

import cx from "classnames"; 

import { 
  trackAddToCart, 
} from "src/components/analytics";

import {
  useAddItemToCart,
  useStore, 
} from "src/context/siteContext";

export const AddButton = ({
  defaultPrice,
  color,
  productId,
  buttonText,
  buttonClass = "p-2 w-full border-2 hover:border-white hover:bg-white duration-200 border-current border-solid rounded-md text-16",
  subscription,
  buttonColor = "border-current text-current",
  cerealImage,
  sub,
  title,
  image,
  tickets = 0
}: {
  defaultPrice: string;
  productId: number;
  color: string;
  cerealImage: {
    asset: {
      _id: string;
    };
  };
  buttonText: string;
  buttonClass: string;
  buttonColor: string;
  tickets?: number;
  sub?: string;
  title: string;
  image: string;
}) => {
  const addItemToCart = useAddItemToCart();
  const { navIsUp, checkout } = useStore();
  const [purchaseType, setPurchaseType] = useState("onetime");
  const [price, setPrice] = useState(defaultPrice);
  const [variant, setVariant] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [available, setAvailable] = useState(false);
  const [activeVariantId, setActiveVariantId] = useState(null as string | null);
  const [check, setCheck] = useState(true);

  useEffect(() => {
    if (check) { 
      storefront
        .fetch(productQuery, {
          id: globalID("Product", productId)
        })
        .then(data => {
          const product = data.product; 
          const variants = product?.variants.edges; 
          const defaultVariant = variants?.[0].node; 

          if (product && defaultVariant) { 
            setActiveVariantId(defaultVariant.id as string);
            setAvailable(defaultVariant.availableForSale);
            setPrice(defaultVariant.price.amount);
            setVariant(defaultVariant); 
            setCheck(false);
          }
        });
    }
  }, [check]);

  const handleSubmit = (e: React.FormEvent) => {
    const eating = new Audio(`/crunch.mp3`);
    e.preventDefault();
    if (available) {
      eating.volume = 0.4;
      eating.play();
      const cleanedImage = image
        .replace("image-", "")
        .replace("-jpg", ".jpg")
        .replace("-png", ".png");
      const customAttributes = [
        {
          key: "_subTitle",
          value: purchaseType === "onetime" ? (sub ? sub : "") : "Subscription"
        },
        {
          key: "_image",
          value: `https://cdn.sanity.io/images/0z8flqaa/production/${cleanedImage}`
        },
        // {key: '_ticketAdd', value: `${purchaseType === 'onetime' ? tickets : tickets * 2}` },
        { key: "_color", value: color }
      ];

      if (purchaseType === "monthly") {
        customAttributes.push({
          key: "_isSubscription",
          value: JSON.stringify(subscription, null, 4)
        });
      }
      
      addItemToCart(activeVariantId, quantity, customAttributes).then(() => {

        return

        trackAddToCart(
          {
            name: title,
            id: activeVariantId || "error",
            price: parseFloat(price),
            variant: variant?.title || "error"
          },
          quantity,
          checkout
        );
      });
    }
  };

  // useEffect(() => {
  //   if (activeVariantId !== null && variant) {
  //     const googleTagManagerProduct: GTMProduct = {
  //       name: title,
  //       id: activeVariantId || "error", // SKU!
  //       price: parseFloat(variant?.price || ""), // string
  //       variant: variant?.title || "error",
  //     }

  //     trackProductDetailView(
  //       [googleTagManagerProduct],
  //       `${title} -- ${variant?.title || "variant_title_error"}`
  //     )
  //   }
  // }, [activeVariantId, variant])

  return (
    <React.Fragment>
      <div className="">
        <form onSubmit={e => handleSubmit(e)}>
          {available && !check ? (
            <React.Fragment>
              <div className="">
                <div className="product__form-button">
                  <button
                    type="submit"
                    className={cx(buttonClass, buttonColor)}
                  >
                    <span>{buttonText ? buttonText : `Add to Cart`}</span>
                  </button>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="">
                <div className="product__form-button">
                  <button
                    type="submit"
                    className={cx(buttonClass, buttonColor)}
                  >
                    <span>{buttonText ? buttonText : `Add to Cart`}</span>
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}
          {!available && !check && (
            <div className={cx("text-center", buttonClass, buttonColor)}>
              <span>Sold Out</span>
            </div>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

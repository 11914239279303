import React from "react"
import cx from 'classnames'

export interface SpacerProps {
  data: {
    backgroundColor: string
  }
}

export const Spacer = ({ data }: SpacerProps) => {
  const { backgroundColor } = data
  return (
    <div className={cx("spacer outer mxa py2 al p1 x", backgroundColor)} />
  )
}
import React from "react"

import { Image } from 'src/components/image'

export interface ImageModuleProps {
  data: {
    image: {}
  }
}

export const ImageModule = ({ data }: ImageModuleProps) => {
  const { image } = data
  return (
    <div className='bg-gray pb-8'>
      <Image imageId={image.asset.id} className='w-full' alt='cereal' />
    </div>
  )
}
import React, { useMemo } from "react";
import { Link } from "gatsby";
import cx from "classnames";

import { AddButton } from "src/components/product/addButton";

import { Image } from "src/components/image";
import { PopupFact } from "../product/purchase";
import { usePage } from "src/context/siteContext";

export interface ProductGridProps {
  data: {
    layout: string;
    title: string;
    backgroundColor: string;
    products: Array<{
      content: {
        main: {
          cleanTitle: string;
          slug: {};
          singleImage: {};
          colorAssociation: string;
        };
        clp: {
          subDescription: string;
          smallImage: {};
          hoverImage: {};
        };
        shopofy: {
          defaultPrice: string;
          productId: number;
        };
      };
    }>;
  };
}

const BundleProduct = ({ shopify, main, clp, length, index, layout }) => {
  const { cleanTitle, subTitle, slug, singleImage, colorAssociation } = main;
  const { hoverImage, subDescription } = clp;
  return (
    <div
      className={cx(
        "p-5 800:px-12 800:py-6 border-t-2 1000:border-b-2 relative overflow-hidden border-black text-black",
        colorAssociation,
        {
          "800:border-r-2": index === 0,
          "border-b-2": index === 1,
          "600:col-span-2": layout === "medium"
        }
      )}
    >
      <div className={cx("relative z-10")}>
        <Link className="hidden 800:block" to={`/products/${slug.current}`}>
          <h2 className="text-40 leading-40 offlimits text-white uppercase">
            {cleanTitle} <br />
            {subTitle && <span className="text-black">{subTitle}</span>}
          </h2>

          {subDescription && (
            <p className="text-18 800:text-24">{subDescription}</p>
          )}
        </Link>
        <div className="800:col-span-5 flex justify-between flex-col">
          <div className={cx("text-center")}>
            <div>
              <Link className="block" to={`/products/${slug.current}`}>
                {singleImage && (
                  <Image
                    imageId={singleImage.asset._id}
                    className="w-full mb-8 mt-8 mx-auto"
                    alt={`${cleanTitle} Cereal`}
                  />
                )}
              </Link>
            </div>
          </div>
          <Link
            className="800:hidden text-center mb-4"
            to={`/products/${slug.current}`}
          >
            <h2 className="text-40 leading-40 offlimits text-white uppercase">
              {cleanTitle} <br />
              {subTitle && <span className="text-black">{subTitle}</span>}
            </h2>

            {subDescription && (
              <p className="text-18 800:text-24">{subDescription}</p>
            )}
            {}
          </Link>
          <div>
            <AddButton
              {...shopify}
              color={colorAssociation}
              sub={subTitle}
              image={singleImage.asset._id}
            />
          </div>
        </div>
      </div>
      {hoverImage && (
        <Image
          imageId={hoverImage.asset._id}
          className="w-full h-full absolute inset-0"
          alt={`${cleanTitle} Cereal`}
        />
      )}
    </div>
  );
};

const StandardProduct = ({ shopify, main, clp, length, index, layout }) => {
  const {
    cleanTitle,
    subTitle,
    slug,
    singleImage,
    colorAssociation,
    popupFacts
  } = main;
  const { subDescription } = clp;
  const { page } = usePage();

  const mediumBorder = layout === "medium" && index !== length;
  const largeBorder = layout === "large" && !(index % 2);

  const hoverImage =
    page === "home" ? clp?.hoverImageTwo || clp?.hoverImage : clp?.hoverImage;

  return (
    <div
      className={cx(
        "p-5 product__card 800:px-12 800:py-6 relative overflow-hidden border-black border-t-2 border-b-2 -mt-0.5 text-black z-0",
        colorAssociation,
        {
          "800:border-r-2": mediumBorder || largeBorder,
          "hover:text-white": hoverImage,
          "": index !== length
        }
      )}
    >
      <Link
        to={`/products/${slug.current}`}
        className="absolute top-0 left-0 w-full h-full"
      ></Link>
      <div
        className={cx("relative z-10 pointer-events-none", {
          "800:grid 800:grid-cols-8": layout === "large"
        })}
      >
        <div className="800:col-span-5 flex justify-between flex-col">
          <div
            className={cx("text-center", {
              "800:text-left 800:h-80": layout === "large"
            })}
          >
            <div
              className={cx({
                "800:hidden": layout === "large"
              })}
            >
              {singleImage && (
                <Image
                  imageId={singleImage.asset._id}
                  className={cx("w-7/12 800:w-full mb-4 mx-auto", {
                    "product__card-box": hoverImage
                  })}
                  alt={`${cleanTitle} Cereal`}
                />
              )}
            </div>
            <div className="block">
              <h2 className="text-40 leading-40 offlimits text-white uppercase">
                {cleanTitle}
              </h2>
              {subTitle && (
                <span className="text-40 leading-40 offlimits uppercase">
                  {subTitle}
                </span>
              )}
            </div>
            {layout === "large" && subDescription && (
              <p className="text-18 hidden 800:block 800:text-24 duration-300 ease-in-out transition-colors">
                {subDescription}
              </p>
            )}
            {page == "all" && (
              <div className="flex mt-2 800:mt-4 flex-wrap pointer-events-auto">
                {popupFacts &&
                  popupFacts?.map((fact, i) => (
                    <PopupFact {...fact} key={fact._key} index={i} />
                  ))}
              </div>
            )}
          </div>
        </div>
        {layout === "large" && (
          <div className="hidden 800:block 800:col-span-3">
            {singleImage && (
              <Image
                imageId={singleImage.asset._id}
                className={cx(
                  "w-full duration-200 mx-auto transform scale-110 mb-10 800:scale-110 1200:mb-20 1200:scale-125 1200:mt-6",
                  {
                    "product__card-box": hoverImage && layout === "large"
                  }
                )}
                alt={`${cleanTitle} Cereal`}
              />
            )}
          </div>
        )}
        <div
          className={cx(
            "col-span-8 mt-2 relative z-10 text-black product__card-button"
          )}
        >
          <AddButton
            {...shopify}
            color={colorAssociation}
            sub={subTitle}
            image={singleImage.asset._id}
          />
        </div>
      </div>
      {hoverImage && (
        <Image
          imageId={hoverImage.asset._id}
          className="w-full opacity-0 duration-200 product__card-hover h-full absolute inset-0 pointer-events-none"
          alt={`${cleanTitle} Cereal`}
        />
      )}
    </div>
  );
};

export const ProductGrid = ({ data }: ProductGridProps) => {
  const { products, title, backgroundColor, layout } = data;
  const { page } = usePage();

  return (
    <div className="">
      {title && (
        <div
          className={cx(
            " text-center py-8 800:pt-20 800:pb-16",
            backgroundColor,
            {
              "text-white": backgroundColor === "bg-black"
            }
          )}
        >
          {title && page && (
            <>
              {page == "all" ? (
                <h3 className="text-20 800:text-36 leading-tighter text-center max-w-800 mx-auto px-4">
                  {title}
                </h3>
              ) : (
                <h3
                  id={title}
                  className="text-40 800:text-72 offlimits uppercase"
                >
                  {title}
                </h3>
              )}
            </>
          )}
        </div>
      )}
      <div className="relative">
        <div
          className={cx(
            "grid grid-cols-1 bg-teal",
            {
              "800:grid-cols-2": layout === "large",
              "600:grid-cols-2 1000:grid-cols-4": layout === "medium"
            }
          )}
        >
          {products.map((product, i) => {
            const clp = product.content.clp || {};
            if (product.content.main.productType === "bundle") {
              return (
                <BundleProduct
                  shopify={product.content.shopify}
                  main={product.content.main}
                  layout={layout}
                  index={i}
                  length={products.length - 1}
                  clp={clp}
                  key={i}
                />
              );
            }
            if (product.content.main.productType === "cereal") {
              return (
                <StandardProduct
                  shopify={product.content.shopify}
                  main={product.content.main}
                  layout={layout}
                  index={i}
                  length={products.length - 1}
                  clp={clp}
                  key={i}
                />
              );
            }
          })}
        </div>
        <div className="h-0.5 w-full bg-black absolute bottom-0"></div>
      </div>
    </div>
  );
};

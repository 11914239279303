import React, { useState } from "react"
import cx from 'classnames'
import {motion, AnimatePresence} from 'framer-motion'
import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"

export interface IncludedFlavorsProps {
  data: {
    flavors: any[]
  }
}

const CerealText = ({ name, sub, color, text, last }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <div className={cx('border-t border-solid border-white border-opacity-30 py-3 1000:pb-0 1000:pt-4', {
      'border-b': last
    })}>
      <div onClick={() => setOpen(!isOpen)} className='flex justify-between items-center'>
        <div className='1000:flex 1000:w-8/12 1000:justify-between 1000:items-center'>
          <h4 className={`leading-40 offlimits m-0 pb-1 pt-1 text-48 800:text-60 1200:text-80 1000:w-6/12 1000:leading-80 ${color} uppercase`}>{name}</h4>
          <h5 className='leading-24 m-0 p-0 text-24 800:text-30 offlimits 1200:text-40 text-white 1000:w-3/12 1000:leading-40 1000:text-left uppercase'>{sub}</h5>
        </div>
        <button className='text-right flex w-2/12 justify-end items-end'>
          <svg className={cx(`inline transform ${color} `, {
            'rotate-45': isOpen
          })} width="68" height="70" viewBox="0 0 68 70" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M26.4201 65.9045L24.6395 64.2631C23.9197 63.5995 22.9654 63.2542 21.9869 63.3029L19.5656 63.4235C12.8743 63.7567 7.23257 58.5559 6.96412 51.807L6.86708 49.3649C6.82787 48.3781 6.40156 47.4507 5.68175 46.7871L3.90115 45.1457C-1.02022 40.6093 -1.32723 32.872 3.2146 27.864L4.85792 26.0521C5.52237 25.3196 5.87324 24.3538 5.83419 23.3666L5.73715 20.9245C5.46907 14.1758 10.676 8.43471 17.3675 8.1012L19.7886 7.98098C20.7672 7.93189 21.6903 7.49311 22.3546 6.76101L23.9979 4.94906C28.5401 -0.0587788 36.2113 -0.440924 41.1327 4.09551L42.9131 5.73726C43.6331 6.40054 44.587 6.74562 45.5658 6.69705L47.9871 6.57647C54.6784 6.24332 60.3205 11.4443 60.5886 18.193L60.6853 20.6349C60.7247 21.6223 61.1512 22.5493 61.8706 23.2128L63.6514 24.8547C68.5724 29.391 68.8798 37.1284 64.3381 42.136L62.6944 43.9478C62.0304 44.6804 61.6793 45.6465 61.7185 46.6334L61.8156 49.0755C62.0837 55.8242 56.8765 61.5657 50.1849 61.8987L47.7639 62.0194C46.7851 62.068 45.8624 62.5069 45.198 63.2394L43.5543 65.0512C39.0126 70.0588 31.3411 70.4408 26.4201 65.9045Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M33.7763 21.4946C33.077 21.4946 32.5102 22.0615 32.5102 22.7608V33.3118H21.9591C21.2599 33.3118 20.693 33.8787 20.693 34.5779C20.693 35.2772 21.2599 35.844 21.9591 35.844H32.5102V46.3951C32.5102 47.0943 33.077 47.6612 33.7763 47.6612C34.4755 47.6612 35.0424 47.0943 35.0424 46.3951V35.844H45.5935C46.2927 35.844 46.8596 35.2772 46.8596 34.5779C46.8596 33.8787 46.2927 33.3118 45.5935 33.3118H35.0424V22.7608C35.0424 22.0615 34.4755 21.4946 33.7763 21.4946Z" fill="black"/>
          </svg>
        </button>
      </div>

      <AnimatePresence initial={true}>
        {isOpen && (
          <motion.div
            className='overflow-hidden'
            key={name}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0}}
            transition={{
              duration: 0.2,
            }}>
            <div className='text-white mt-4 mb-4 line-break'>
              {text && (<BlockContent blocks={text} serializers={Serializer} />)}
            </div>
          </motion.div>
          )}
        </AnimatePresence>

    </div>
  )
}

export const IncludedFlavors = ({ data }: IncludedFlavorsProps) => {
  const { flavors } = data
  const renderFlavors = flavors.map(({colorAssociation, _key, title, text, flavor}, i) => (
    <CerealText
      key={_key}
      text={text}
      name={title}
      sub={flavor}
      last={i === flavors.length - 1}
      color={colorAssociation} />
  ))
  return (
    <div className='bg-black p-4 800:p-8 800:pt-20 800:pb-20'>
      {renderFlavors}
    </div>
  )
}
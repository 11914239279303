import React, { useEffect, useState } from 'react'
import EmblaCarouselReact from 'embla-carousel-react'
import cx from 'classnames'

import {
  ArrowTick
} from 'src/components/svgs'

import { Image } from 'src/components/image'

export interface ImageSlideshowProps {
  data: {
    backgroundColor: string
    title?: string
    images: Array<{
      _key: string
      asset: {
        _id: string
      }
    }>
  }
}

export const ImageSlideshow = ({ data }: ImageSlideshowProps) => {
  const { backgroundColor, title, images } = data
  const [embla, setEmblaInsta] = useState(null)
  const [index, setIndex] = useState(0)
  
  useEffect(() => {
    if (embla) {
      embla.on('select', () => {
        setIndex(embla.selectedScrollSnap())
      })
    }
  }, [embla])

  return (
    <div className={cx('image__slideshow mxa py2 al ac x', backgroundColor, {
      'cw': backgroundColor === 'bcb'
    })}>
      {title && (<h3 className='h2 offlimits caps'>{title}</h3>)}
      <div className='image__slideshow-wrapper rel'>
        <EmblaCarouselReact
          emblaRef={setEmblaInsta}
          options={{ loop: true, startIndex: 1, align: 'center' }}>
            <div className='x f image__slideshow-inner mt1 embla__container'>
              {images.map(({
                asset
              }, i) => (
                <div className='image__slideshow-slide p1 embla__slide x ac' key={i}>
                  <Image imageId={asset._id} className='x' alt={title} />
                </div>
              ))}
            </div>
          <button aria-label='previous slide' className='image__slideshow-arrow review__arrow abs bcy left m1 cb' onClick={() => embla.scrollPrev()}><ArrowTick /></button>
          <button aria-label='next slide' className='image__slideshow-arrow abs review__arrow bcy last cb m1 right' onClick={() => embla.scrollNext()}><ArrowTick /></button>
        </EmblaCarouselReact>
      </div>
    </div>
  )
}
import React, { useState, useEffect} from 'react'
import cx from 'classnames'
import EmblaCarouselReact from 'embla-carousel-react'

import BlockContent from '@sanity/block-content-to-react'

import {
  ArrowTick
} from 'src/components/svgs'

import { Serializer } from 'src/utils/serializer'
import { Image } from 'src/components/image'

export interface PartnersProps {
  data: {
    backgroundColor: string
    partners: Array<{
      brandImage: {}
      title?: string
      description: []
      brandLogo: {}
    }>
  }
}

export const Partners = ({ data }: PartnersProps) => {
  const [embla, setEmbla] = useState(null)
  const [index, setIndex] = useState(0)
  const [sizeCheck, setSizeCheck] = useState(false)
  const [alignment, setAlignment] = useState('start')
  const { backgroundColor, partners } = data

  useEffect(() => {
    if (embla) {
      embla.on('select', () => {
        setIndex(embla.selectedScrollSnap())
      })
    }
  }, [embla])

  useEffect(() => {
    if (!sizeCheck) {
      if (window.innerWidth < 1000) {
        setAlignment('center')
      }
      setTimeout(() => {
        setSizeCheck(true)
      }, 400)
    }
  }, [sizeCheck])

  return (
    <div className={cx('x partners py1 al rel z1 x', backgroundColor, {
      'cw': backgroundColor === 'bcb'
    })}>
      <div className='partners__wrapper container--l mxa jcb ais'>
        {partners.length > 1 && (
          <React.Fragment>
            <button className='review__arrow abs bcy bottom m1 cb' onClick={() => embla.scrollPrev()}><ArrowTick /></button>
            <button className='review__arrow abs bcy bottom last cb m1' onClick={() => embla.scrollNext()}><ArrowTick /></button>
          </React.Fragment>
        )}
        <div className='partners__text rel z1'>
          {partners.map(({
            title,
            description,
          }, i) => (
            <div key={i} className={cx('partners__text-nested mt1', {
              'show': i === index
            })}>
              <h3 className='offlimits s48 caps'>{title}</h3>
              {description && <BlockContent blocks={description} serializers={Serializer} />}
            </div>
          ))}
        </div>
        <div className='partners__slideshow'>
          {sizeCheck && (
            <EmblaCarouselReact
              emblaRef={setEmbla}
              options={{ loop: true, startIndex: 1, align: 'center' }}>
              <div className='x f embla__container'>
                {partners.map(({
                  brandImage,
                  title,
                  description,
                  brandLogo
                }, i) => (
                  <div className='partners__slide p1 embla__slide x ac' key={i}>
                    <div className='box-shadow-sm rel ba'>
                      <div className='partners__logo ba bcw p1 f jcc aci abs z1 bottom left m1'>
                        <Image imageId={brandLogo.asset._id} className='x' alt={`${title} logo`} />
                      </div>
                      <Image imageId={brandImage.asset._id} className='x' alt={`${title} image`} />
                    </div>
                    <div className='p1 mt1 partners__slide-text'>
                      <h3 className='caps offlimits'>{title}</h3>
                      {description && <BlockContent blocks={description} serializers={Serializer} />}
                    </div>
                  </div>
                ))}
              </div>
            </EmblaCarouselReact>
          )}
        </div>
      </div>
    </div>
  )
}
import React from "react"
import { Image } from 'src/components/image'

export interface ISpyProps {
  data: {
    title: string
    text: any[]
    image: {}
  }
}

export const ISpy = ({ data }: ISpyProps) => {
  const { title, text, image } = data
  return (
    <div className='bg-black pt-4'>
      <div className='grid p-4 800:pl-8 800:pr-8 grid-cols-1 800:grid-cols-2'>
        <h3 className='text-white text-center 800:text-left text-48 800:text-60 800:leading-60 offlimits uppercase'><span className='inline-block 1000:m-w-3/4'>{title}</span></h3>
        <div className='hidden 800:block 800:ml-8'>
          <ol className='mono text-white text-14 uppercase grid-cols-2 grid'>
            {text.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ol>
        </div>
      </div>
      <div className='800:p-4'>

        {image && (<Image imageId={image.asset._id} className='w-full 800:rounded-3xl overflow-hidden  mb-4 mx-auto' alt={`${title} Image`} />)}
      </div>
      <div className='800:hidden p-4 ml-8 pb-10'>
        <ol className='mono text-white text-14 uppercase grid-cols-2 grid'>
          {text.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ol>
      </div>
    </div>
  )
}